import * as React from 'react';

import * as e from '@fe/components/Elements';
import * as c from './components';
import { setParam } from '@fe/views/common/routeHandler';

export default class Header extends React.Component {

  public render = () => {
    return (
      <>
        <c.HeaderSpacing />
        <c.HeaderBar>
          <c.LogoContainer>
            <c.Logo />
          </c.LogoContainer>
          <c.ButtonsContainer>
            <c.TryNowButton onClick={() => setParam(`auth`, `register`)}>
              Try now for free
            </c.TryNowButton>
            <e.Button.default
              onClick={() => setParam(`auth`, `login`)}
              variant={`secondary`}
            >
              login
            </e.Button.default>
          </c.ButtonsContainer>
        </c.HeaderBar>
      </>
    );
  }
}
