import * as React from 'react';
import * as Yup from 'yup';

import { sendFeedback, ISentryFeedback } from '@fe/helpers/client/sentry';
import Modal from './base';
import * as e from '@fe/components/Elements';
import * as c from './components';
import * as Form from '@fe/components/Form';
import styled from '@fe/styles';

const ContentContainer = styled.div`
  margin: 30px auto 50px;
`;
const SupportContainer = styled.div`
  font-size: 12px;
  margin: 20px auto 12px;
  max-width: 250px;
  color: rgb(162,162,162);
`;
const FeedbackLinkContainer = styled.div`
  font-size: 12px;
  margin: 20px auto;
  color: rgb(162,162,162);
  text-align: center;
`;
const CloseLink = styled.div`
  margin-top: 30px;
`;

const ErrorFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, `please enter at least 2 letters`)
    .matches(/^[A-Za-z ]*$/, 'please only enter letters')
    .required('please enter your name')
    .ensure(),
  email: Yup.string()
    .email('please enter a valid email address')
    .required('please enter your email address')
    .ensure(),
  comments: Yup.string()
    .required('please enter your comments')
    .ensure(),
});

export default class ErrorModal extends Modal<{
  allowFeedback?: boolean,
  allowSupport?: boolean,
  eventId?: string,
  titleMessage?: string,
}, {
  isFeedbackOpen: boolean,
  isFeedbackSent: boolean,
  isValid: boolean,
}> {
  public static defaultProps = {
    ...Modal.defaultProps,
    children: null,
    titleMessage: `oops, something went wrong`,
    allowSupport: false,
    allowFeedback: false,
  };
  public state = {
    isFeedbackOpen: false,
    isFeedbackSent: false,
    isValid: false,
  };

  protected renderContent = () => {
    const {
      titleMessage,
      children,
      allowFeedback,
      allowSupport,
    } = this.props;
    const {
      isFeedbackSent,
    } = this.state;
    return !isFeedbackSent ? (
      <>
        <ContentContainer>
          <c.Header>
            {titleMessage}
          </c.Header>
          {children && (
            <c.Paragraph>
              {children}
            </c.Paragraph>
          )}
        </ContentContainer>
        {
          allowSupport && this.renderSupport()
        }
        {
          allowFeedback && this.renderFeedbackForm()
        }
      </>
    ) : (
      <>
        <c.Header>
          thanks
        </c.Header>
        <c.Paragraph>
          Thank you for taking the time to provide us with feedback!
          It will allow us to fix the problem more quickly for you and our
          other customers
        </c.Paragraph>
        <CloseLink>
          <e.a.default onClick={this.props.onClose}>Close dialog</e.a.default>
        </CloseLink>
      </>
    );
  }

  protected renderFeedbackForm = () => {
    const {
      isFeedbackOpen,
    } = this.state;
    return (
      <>
        <FeedbackLinkContainer>
          <e.Button.Link onClick={() => this.setState({ isFeedbackOpen: !isFeedbackOpen })}>Tell us what went wrong</e.Button.Link>
        </FeedbackLinkContainer>
        <e.Collapse.CollapseContent isOpened={isFeedbackOpen}>
          <Form.Container
            validationSchema={ErrorFormSchema}
            onSubmit={(formData: ISentryFeedback) => {
              this.sendFeedback(this.props.eventId, formData)
                // tslint:disable-next-line:no-console
                .catch(console.error);
            }}
          >
            {(form) => (
              <>
                <Form.Row>
                  <Form.Input
                    label='your name'
                    name='name'
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Input
                    label='your email address'
                    name='email'
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Textarea
                    placeholder='What happened?'
                    name='comments'
                  />
                </Form.Row>
                <Form.Row>
                  <e.Button.FormButton
                    type={'submit'}
                    disabled={!form.isValid}
                    isLarge
                    isBlock
                  >
                    Leave feedback
                  </e.Button.FormButton>
                </Form.Row>
              </>
            )}
          </Form.Container >
        </e.Collapse.CollapseContent>
      </>
    );
  }

  protected renderSupport = () => {
    return (
      <SupportContainer>
        <e.Text.Paragraph>
          {`Please try again, or if the problem persists, drop us an
          email on `}
          <e.a.default href={`mailto:help@carbonex.co`}>help@carbonex.co</e.a.default>
          {` to let us know and we’ll be happy to help!`}
        </e.Text.Paragraph>
      </SupportContainer>
    );
  }

  protected sendFeedback = (eventId, form): Promise<any> => {
    return sendFeedback(eventId, form)
      .then(() => {
        this.setState({
          isFeedbackSent: true,
        });
      }).catch(() => {
        this.setState({
          isFeedbackSent: true,
        });
      });
  }
}
