
import * as deepmerge from 'deepmerge';

import { ApolloCache } from 'apollo-cache';

interface IConfig<T> {
  updateBeforeWrite?: (data: T) => T;
}
const updateCache = <T>(cache: ApolloCache<T>) => (query: any, data: Partial<T>, config: IConfig<T> = {}): T => {
  const previousQueryData = cache.readQuery({
    query,
  })!;
  let updatedQueryData = deepmerge(previousQueryData, data);

  if (config.updateBeforeWrite) {
    updatedQueryData = deepmerge(updatedQueryData, config.updateBeforeWrite(updatedQueryData));
  }

  cache.writeQuery({
    query,
    data: updatedQueryData,
  });

  return updatedQueryData;
};

export default updateCache;
