import * as React from 'react';

import styled, { css } from 'styled-components';
import { InputType } from './types';
import { Field, FieldProps } from 'formik';

import * as gtm from '@fe/services/gtm';

const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  font-family: ${(p) => p.theme.font.sans};
`;

const RadioButtonLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
`;

const RadioButton = styled.span<{
  checked: boolean,
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.color.lightGrey};
  :after {
    content: "";
    position: absolute;
    display: ${(p) => {
      if (p.checked) {
        return 'block;';
      }
      return 'none;';
    }}
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #000;
}
`;

const LabelText = styled.div<{
  checked: boolean,
}>`
  color: ${(p) => p.checked ? '#000' : p.theme.color.lightGrey};
  font-size: 18px;
`;
const Input = styled.input<{
  isValid: boolean,
}>`
  position: absolute;
  opacity: 0;
`;
const ErrorMessage = styled.div`
`;

interface IRadioGroup<T> {
  getErrorMessage: () => string;
  getValue: () => T;
  isPristine: () => boolean;
  isValid: () => boolean;
  name: string;
  setValue: (value: T) => void;
}

// @(connect)
class RadioGroupComponent<T> extends React.Component<InputType<{
  children: (data: IRadioGroup<T>) => JSX.Element,
}>> {
  public render = () => {
    const {
      children,
    } = this.props;
    const { name } = this.props;
    return (
      <Field
        name={name}
        render={(props: FieldProps) => {
          const { form, field } = props;
          return children({
            setValue: (value) => form.setFieldValue(name, value),
            getValue: () => field.value,
            isValid: () => !!form.errors[name],
            getErrorMessage: () => form.errors[name],
            isPristine: () => form.touched[name],
          });
        }}
      />
    );
  }
}
export const RadioGroup: React.Component<{
  children: (group: IRadioGroup<any>) => JSX.Element,
  name: string,
  value: any,
}> = RadioGroupComponent;

// tslint:disable-next-line:max-classes-per-file
export default class Radio<T> extends React.Component<{
  group: IRadioGroup<T>,
  label: string,
  value: T,
}, {
  isDirty: boolean,
}> {

  constructor(props) {
    super(props);
    this.state = {
      isDirty: !this.props.group.isPristine(),
    };
  }
  public render = () => {
    const {
      isDirty,
    } = this.state;
    const {
      group,
      label,
      value,
      ...rest
    } = this.props;
    const isValidValue = group.isValid();
    const checked = value === group.getValue();

    return (
      <RadioButtonContainer>
        <RadioButtonLabel>
          <Input
            isValid={isValidValue}
            checked={checked}
            type='radio'
            name={group.name}
            onChange={() => {
              group.setValue(value);
              gtm.sendSelect(`radio`, group.name, {
                value,
              });
            }}
            {...rest}
          />
          <RadioButton checked={checked}></RadioButton>
          <LabelText checked={checked}>{label}</LabelText>
        </RadioButtonLabel>
        {!isValidValue && isDirty && (
          <ErrorMessage>
            {group.getErrorMessage()}
          </ErrorMessage>
        )}
      </RadioButtonContainer>
    );
  }
}
