import * as React from 'react';

export default class Hamburger extends React.Component {

  public render = () => {
    return (
      <svg {...this.props} viewBox='0 0 30 20' version='1.1' xmlns='http://www.w3.org/2000/svg' >
          <title>hamburger</title>
          <g id='Homepage' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <g id='icon/hamburger' transform='translate(-9.000000, -14.000000)' fill='currentColor' fillRule='nonzero'>
                  <path d='M9,14 L39,14 L39,17 L9,17 L9,14 Z M9,22 L36,22 L36,25 L9,25 L9,22 Z M9,31 L27,31 L27,34 L9,34 L9,31 Z' id='Combined-Shape'></path>
              </g>
          </g>
      </svg>
    );
  }
}
