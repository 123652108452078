
import axios from 'axios';

const cx = axios.create({
  baseURL: '/',
});

export const login = async (email: string, password: string) => {
  return cx.post(`/api/auth/login`, {
    email,
    password,
  });
};
export const join = async (user: { firstName: string, lastName: string, email: string }) => {
  return cx.post(`/api/user`, user);
};

export default cx;
