import * as React from 'react';
import TippyTooltip from '@fe/components/TippyTooltip';
import { injectGlobal, defaults, ThemeProvider } from '@fe/styles';

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  .tippy-tooltip {
    font-family: ${defaults.font.sans};
    box-shadow: ${defaults.other.boxShadowStrong};
    font-weight: 600;
    padding: 8px;
    border-radius: ${defaults.other.borderRadius};
  }
`;
export default class Tooltip extends React.Component<{
  children: JSX.Element,
  disabled?: boolean,
  onHide?: () => void,
  onShow?: () => void,
  open?: boolean,
  position?: string
  title: string,
}, {
  arrowRef: any,
  isOpen: boolean,
}> {
  public static defaultProps = {
    onShow: () => null,
    onHide: () => null,
  };
  public state = {
    arrowRef: null,
    isOpen: false,
  };
  private onShow = (isOpen: boolean) => {
    if (this.props.open === undefined) {
      this.setState({
        isOpen,
      });
    }
    isOpen ? this.props.onShow!() : this.props.onHide!();
  }
  public render = () => {
    const {
      children,
      onShow,
      onHide,
      open,
      html,
      ...rest
    } = this.props;
    const {
      isOpen,
    } = this.state;

    return (
        <TippyTooltip
          duration={200}
          animateFill={false}
          arrow
          theme={`light`}
          open={open === undefined ? isOpen : open}
          onShow={() => this.onShow(true)}
          onHide={() => this.onShow(false)}
          html={html ? (
            <ThemeProvider theme={defaults}>
              {html}
            </ThemeProvider>
          ) : undefined}
          {...rest}
        >
          {children}
        </TippyTooltip>
    );
  }
}
