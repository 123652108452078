import * as Immutable from 'immutable';

import { exampleActions } from '../actions/exampleActions';

const DefaultState = Immutable.Record({
  isFetching: false,
  data: null,
  error: null,
  lastModified: null,
  test: 0,
});

const initialState = new DefaultState();

export default function example(state = initialState, action: any) {
  switch (action.type) {
    case exampleActions.TEST_EXAMPLE:
      return state.merge({
        test: state.get(`test`) + 1,
      });

    default:
      return state;
  }
}
