import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Error as ErrorModal } from '@fe/components/Modal';

import config from '@fe/config';
import * as gtm from '@fe/services/gtm';

interface IProps extends RouteComponentProps {
  children: JSX.Element | Array<JSX.Element>;
}
class ErrorBoundary extends React.Component<IProps, {
  eventId?: string,
  isError: boolean,
  isModalOpened: boolean,
}> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isError: false,
      isModalOpened: false,
    };
  }

  public componentDidCatch = (error, errorInfo): void => {
    this.setState({ isError: true });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  public componentDidMount = (): void => {
    this.initSentry(this);
  }

  public initSentry = (context: any) => {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.env,
      release: config.version,
      integrations: [new Sentry.Integrations.ExtraErrorData({ depth: 8 })],
      beforeSend(event) {
        const {
          event_id,
          exception,
        } = event;

        context.setState({ eventId: event_id });
        // Check if it is an exception, if so, show the report dialog
        if (exception) {
          context.setState({ isModalOpened: true });
        }

        gtm.sendError({
          exception,
          id: event_id,
          environment: config.env,
          release: config.version,
        });

        return event;
      },
    });
    // Set user information, as well as tags and further extras
    Sentry.configureScope((scope) => {
      scope.setTag('app_version', config.version);
    });
  }

  public render = () => {
    const {
      children,
    } = this.props;

    const {
      isModalOpened,
    } = this.state;

    return (
      <>
        {children}
        <ErrorModal
          isOpen={ isModalOpened }
          onClose={() => this.setState({ isModalOpened: false })}
          allowSupport
          allowFeedback
          eventId={this.state.eventId}
        />
      </>
    );
  }
}

export default withRouter(ErrorBoundary);
