// Client entry point

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import 'core-js/modules/es7.promise.finally';
import * as Sentry from '@sentry/browser';
import { loadableReady } from '@loadable/component';

// Create browser history, for navigation a la single page apps
import history from '@fe/views/common/history';
import config from '@fe/config';

// React, our UI engine
import * as React from 'react';
// import * as Immutable from 'immutable';

// import { Provider } from 'react-redux';
// HOC for enabling Apollo GraphQL `<Query>` and `<Mutation>`
import { ApolloProvider } from 'react-apollo';

// Attach React to the browser DOM
import * as ReactDOM from 'react-dom';
import * as Immutable from 'immutable';

// Single page app routing
import { Router } from 'react-router-dom';
import { RouterToUrlQuery } from 'react-url-query';
import { Provider } from 'react-redux';

/* Local */

// Our main component, and the starting point for server/browser loading
import Root from '@fe/views/root';

// Helper function that creates a new Apollo client per request
import { createClient } from '@fe/graphql/apollo';

// For Styled Components theming
import { ThemeProvider } from '@fe/styles/styledComponents';

// ... and the actual Styled Components theme
import defaultTheme from '@fe/styles/themes/default';

import configureStore from '../state';
import serverState from '@fe/serverState';

// ----------------------------------------------------------------------------


interface IWindowWithState extends Window {
  initialState: any;
}

const windowWithState = window as IWindowWithState;

const initialState = Immutable.fromJS(windowWithState.initialState);

const configuredStore = configureStore(history, initialState);

// Create Apollo client
createClient(config.cookieKey)
  .then((client) => {
    // Render
    loadableReady(() => {
      ReactDOM.hydrate(
        <ThemeProvider theme={defaultTheme}>
          <ApolloProvider client={client}>
            <Provider store={configuredStore}>
              <Router history={history}>
                <RouterToUrlQuery>
                  <Root disableAnalytics={(serverState && serverState.disableAnalytics) || false} />
                </RouterToUrlQuery>
              </Router>
            </Provider>
          </ApolloProvider>
        </ThemeProvider>,
        document.getElementById('root'),
      );
    });
  })
  .catch((e) => {
    // tslint:disable-next-line:no-console
    console.error('error creating client in fe', e);
    const ExtraErrorData = Sentry.Integrations.ExtraErrorData as any;
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.env,
      release: config.version,
      integrations: [new ExtraErrorData({ depth: 8 })],
    });
    Sentry.captureException(e);
  });
