import styled from '@fe/styles/styledComponents';

export const AppContainer = styled.div`
  background: ${(p) => p.theme.color.page};
  color: ${(p) => p.theme.color.text};
  font-family: ${(p) => p.theme.font.sans};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
