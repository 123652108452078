import * as React from 'react';
import styled, { css } from '@fe/styles';

export const PageSection = styled.section<{
  isCentered?: boolean,
}>`
  max-width: ${(p) => p.theme.layout.maxWidth};
  margin: 40px auto;
  ${(p) => p.isCentered && css`text-align: center;`}
`;

const CenterContentOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const CenterContentInner = styled.div`
  text-align: center;
`;
export const CenterContent: React.SFC<{children: JSX.Element}> = ({ children }) => (
  <CenterContentOuter>
    <CenterContentInner>
      {children}
    </CenterContentInner>
  </CenterContentOuter>
);

export const Content = styled.div<{
  isLeft?: boolean,
}>`
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  ${(p) => p.theme.layout.paddingResizeCss}
  ${(p) => p.theme.media.phablet`
    text-align: ${p.isLeft ? `left` : `center`};
  `}
`;

/**
 * A container that provides basic padding to a section
 */
export const Section = styled.div<{
  align?: `left` | `center` | `right`,
}>`
  ${(p) => p.theme.layout.paddingResizeCss}
  text-align: ${(p) => p.align || `left`};
`;

/**
 * A container useful for structuring a row with horizontal items
 */
export const Row = styled.div<{
  alignItems?: string;
  justifyContent?: string;
  disableBottomSpacing?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex: 1;
  ${(p) => !p.disableBottomSpacing && css`margin-bottom: 6px;`}
  align-items: ${(p) => p.alignItems || `flex-start`};
  justify-content: ${(p) => p.justifyContent || `space-between`};
`;
export const Cell = styled.div<{
  horizontalPadding?: string;
}>`
  margin-left: ${(p) => p.horizontalPadding || p.theme.layout.spacingHorizontal};
  margin-right: ${(p) => p.horizontalPadding || p.theme.layout.spacingHorizontal};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-left: 0;
    margin-right: 0;
  }
`;
