import * as Cookies from 'cookies-js';
import * as deepmerge from 'deepmerge';

export const merge = <T>(key: string, data: T): T => {
  const dataTextWas = Cookies.get(key);
  let newData = data;
  if (dataTextWas) {
    const dataWas = JSON.parse(dataTextWas);
    newData = deepmerge(dataWas, data);
  }
  Cookies.set(key, JSON.stringify(newData));
  return newData;
};

export default Cookies;
