import * as React from 'react';
import { connect } from 'formik';
import * as Sentry from '@sentry/browser';
import flatten from 'flat';

interface IExternalProps {
  order?: Array<string>;
}

class AutoFocusOnError extends React.Component<IExternalProps> {

  private getSelector = (name) => `[name="${name}"]`;

  private getTopKey = (errorKeys: Array<string>): string => {
    let highestInputTopValue: number | null = null;
    let highestInputName: string = errorKeys[0];
    errorKeys.forEach((errorKey) => {
      const selector = this.getSelector(errorKey);
      const errorElement = document.querySelector(selector) as HTMLInputElement;
      if (errorElement) {
        const boundingRect = errorElement.getBoundingClientRect();
        const inputTopValue = boundingRect.top;
        const isHigher = highestInputTopValue === null || highestInputTopValue > inputTopValue;
        if (isHigher) {
          highestInputTopValue = inputTopValue;
          highestInputName = errorKey;
        }
      }
    });
    return highestInputName;
  }
  public componentDidUpdate = (prevProps) => {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(flatten(errors));
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const key = this.getTopKey(keys);
      const selector = `[name="${key}"] `;
      const errorElement = document.querySelector(selector) as HTMLInputElement;
      if (errorElement) {
        errorElement.focus();
      }
    }
  }

  public componentDidCatch = (error): void => {
    Sentry.captureException(error);
  }

  public render = () => null;
}

export default connect(AutoFocusOnError) as React.SFC<IExternalProps>;
