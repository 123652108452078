/* tslint:disable no-unused-expression */

// Global styles

/* NPM */
import styledComponents, {
  css,
  keyframes,
  ThemeProvider,
  injectGlobal,
  defaults,
} from './styledComponents';
import * as pose from './pose';

// Inject Styled-Components output onto the page. By default, this is blank --
// you can add global styles to the template tags below
injectGlobal`
  * {
    box-sizing: border-box;
    font-family: ${defaults.font.sans};
  }
  html {
    padding: 0;
    border: 0;
    font-size: 16px;
    scroll-behavior: smooth;
    overflow: hidden;
    width: 100%;
    width: 100vw;
    height: 100%;
    height: 100vh;
  }
  body {
    padding: 0;
    border: 0;
    margin: 0;
    height: 100%;
    height: 100vh;
    width: 100%;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${defaults.color.page};
  }
  body.modal-open {
    overflow: hidden;
  }
  body.modal-open-fixed {
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
`;
export {
  css,
  keyframes,
  injectGlobal,
  ThemeProvider,
  defaults,
  pose,
};
export default styledComponents;
