import { css, InterpolationValue } from 'styled-components';

type MediaQuery = (...args: any[]) => InterpolationValue[];
export interface IMedia {
  custom: (size: string) => InterpolationValue[];
  desktop: MediaQuery;
  largeDesktop: MediaQuery;
  phablet: MediaQuery;
  phone: MediaQuery;
  sizes: {
    desktop: number,
    largeDesktop: number,
    phablet: number,
    phone: number,
    tablet: number,
  };
  tablet: MediaQuery;
}

const sizes = {
  largeDesktop: 1200,
  desktop: 992,
  tablet: 768,
  phablet: 480,
  phone: 320,
};

const mediaTags = Object.keys(sizes).reduce((acc: any, label) => {
  acc[label] = (...args: any[]) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const media: IMedia = {
  ...mediaTags,
  sizes,
  custom: (size) => (...args: any[]) => css`
      @media (min-width: ${size}) {
        ${css(...args)}
      }
    `
  ,
};

export default media;
