import * as React from 'react';
import { Formik, Form, FormikConfig, FormikProps } from 'formik';
import * as gtm from '@fe/services/gtm';
import AutoFocusOnError from './autoFocusOnError';

export default class FormContainer<T> extends React.Component<{
  children: JSX.Element | Array<JSX.Element> | ((props: FormikProps<T>) => JSX.Element),
  innerRef?: React.RefObject<HTMLFormElement>,
  onChange?: (formData: T) => void,
  onFocusChanged?: (isFocused: boolean, formData: T) => void,
  onInvalid?: () => void,
  onValid?: () => void,
  name: string,
  disableAutoFocus?: boolean,
  // Using on valid changed reduces the render cycles as it only updates the state when there is a change
  onValidChanged?: (isValid: boolean) => void,
} & Formik<T> & FormikConfig<T>, {
  isInited?: boolean,
  isInitialValid?: boolean,
  isValid?: boolean,
}> {

  constructor(props) {
    super(props);
    this.state = {
      isInited: false,
      isValid: false,
      isInitialValid: false,
    };
  }
  private onChange = (values) => {
    if (!this.props.onChange) return null;
    this.props.onChange(values);
  }

  protected checkValidation = (props: FormikProps<T>) => {
    const {
      isValid,
    } = this.state;
    if (props.isValid !== isValid) {
      this.setState({
        isValid: props.isValid,
      });
      if (this.props.onValidChanged) this.props.onValidChanged(props.isValid);
    }
  }

  public componentDidMount = () => {
    const {
      validationSchema,
      initialValues,
    } = this.props;
    if (validationSchema) {
      validationSchema.isValid(initialValues)
        .then((isValid) => {
          this.setState({
            isInitialValid: isValid,
          });
        });
    }
  }
  public render = () => {
    const {
      children,
      innerRef,
      initialValues,
      validationSchema,
      onSubmit,
      onFocusChanged,
      disableAutoFocus,
    } = this.props;

    return (
      <Formik
        ref={innerRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        isInitialValid={this.props.isInitialValid || this.state.isInitialValid}
        validate={(values) => {
          this.onChange(values);
        }}
      >
        {(props) => {
          this.checkValidation(props);
          return (
            <Form
              onBlur={() => {
                this.onChange(props.values);
                if (onFocusChanged) onFocusChanged(false, props.values);
              }}
              onFocus={() => {
                if (onFocusChanged) onFocusChanged(true, props.values);
              }}
            >
              {typeof children === `function` ? children(props) : children}
              {!disableAutoFocus && <AutoFocusOnError />}
            </Form>
          );
        }}
      </Formik >
    );
  }
}
