import { compose, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import immutableMerger from 'redux-storage-merger-immutablejs';
import filter from 'redux-storage-decorator-immutable-filter';
import debounce from 'redux-storage-decorator-debounce';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from './reducers';
import rootSaga from './sagas';

import config from '@fe/config';

interface IDevToolsWindow extends Window {
  devToolsExtension: any;
}

export default function configureStore(history: any, initialState: any) {
  const middleware = [];
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);
  middleware.push(routerMiddleware(history));

  let finalReducer = rootReducer;
  let engine;

  if (false) {
    finalReducer = storage.reducer(rootReducer, immutableMerger);
    engine = createEngine(`frontend`);
    engine = filter(engine, [`applications`]);
    engine = debounce(engine, 1500);

    const storageMiddleware = storage.createMiddleware(engine);
    middleware.push(storageMiddleware);
  }

  if (config.loggerEnabled && !TEST) {
    const logger = createLogger({
      stateTransformer: (state: any) => {
        let newState = {};
        if (state.toJS) {
          newState = state.toJS();
        }
        return newState;
      },
    });
    middleware.push(logger);
  }

  const devToolWindow = window as IDevToolsWindow;

  // const devTools = DEV && !SERVER && typeof devToolWindow.devToolsExtension !== `undefined`
  //   ? devToolWindow.devToolsExtension()
  //   : (f: any) => f;

  const composedCreateStore = compose(
    applyMiddleware(...middleware),
  );

  const finalCreateStore = composedCreateStore(createStore);

  const store = finalCreateStore(finalReducer, initialState);

  sagaMiddleware.run(rootSaga);

  if (DEV) {
    // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
    // if (module.hot) {
    //   module.hot.accept(`./reducers`, () =>
    //     store.replaceReducer(require(`./reducers`).default),
    //   );

    //   // module.hot.accept('../sagas/SagaManager', () => {
    //   //   SagaManager.cancelSagas(store);
    //   //   require('../sagas/SagaManager').default.startSagas(sagaMiddleware);
    //   // });
    // }
  }

  return store;
}
