import { Chance } from 'chance';
import { CheckoutForm, Address, AddressType } from '@graphql/types/client';
const random = Chance();

export const createCheckoutForm = (): CheckoutForm => {
  const line1 = random.sentence({ words: 4 });
  const firstName = random.first();
  const lastName = random.last();
  const createAddress = (noPostcode?: boolean): Address => {
    return {
      firstName,
      lastName,
      line1,
      __typename: `Address`,
      postcode: noPostcode ? null : random.pickone([`SE14JZ`, `NW64SN`]),
      fullAddress: line1,
      line2: random.word(),
      town: random.word(),
      county: random.word(),
    };
  };
  return {
    __typename: 'CheckoutForm',
    account: {
      firstName,
      lastName,
      __typename: 'AccountForm',
      email: random.email({ domain: `gmail.com` }),
      phoneNo: `${random.phone({ country: 'uk', mobile: true, formatted: false })}`,
      specialInstructions: null,
      deliveryDate: '',
      address: createAddress(),
    },
    payment: {
      __typename: 'PaymentForm',
      nameOnCard: random.name(),
      useAddressType: AddressType.Billing,
      address: createAddress(),
      acceptsMarketing: true,
    },
  };
};
