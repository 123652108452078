import * as React from 'react';
import * as yup from 'yup';
import Modal from './base';
import * as e from '@fe/components/Elements';
import * as Form from '@fe/components/Form';

import { login } from '@fe/helpers/client/cx';

const LoginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export default class LoginModal extends Modal<{}, {
  isInvalidLogin: boolean;
}> {
  public state = {
    isInvalidLogin: false,
  };

  public renderContent = () => {
    const {
      isInvalidLogin,
    } = this.state;

    return (
      <>
        <e.Text.h3>
          Login
        </e.Text.h3>
        <p>
          Please enter your email and password.
        </p>
        <Form.Container
          validationSchema={LoginSchema}
          onSubmit={async (values: any) => {
            try {
              this.setState({
                isInvalidLogin: false,
              });
              await login(values.email, values.password);
            } catch (e) {
              this.setState({
                isInvalidLogin: true,
              });
            }
          }}
        >
          {() => (
            <>
              <Form.Row>
                <Form.Input
                  label={`Email`}
                  name={`email`}
                />
              </Form.Row>
              <Form.Row>
                <Form.Input
                  label={`Password`}
                  name={`password`}
                  type={`password`}
                />
              </Form.Row>
              {isInvalidLogin && (
                <Form.Row>
                  <Form.ErrorText>Invalid email or password. Please try again.</Form.ErrorText>
                </Form.Row>
              )}
              <e.Button.Basic
                type={`submit`}
              >
                Login
              </e.Button.Basic>
            </>
          )}
        </Form.Container>
      </>
    );
  }
}
