import styled, { css } from '@fe/styles';
import * as React from 'react';

const PaypalIconSrc = 'images/allplants/paypal.png';
const DinersIconSrc = 'logos/paymentTypes/dark/diners.png';
const DiscoverIconSrc = 'logos/paymentTypes/dark/discover.png';
const JcbIconSrc = 'logos/paymentTypes/dark/jcb.png';
const MastercardIconSrc = 'logos/paymentTypes/dark/mastercard.png';
const VisaIconSrc = 'logos/paymentTypes/dark/visa.png';
const AmexIconSrc = 'logos/paymentTypes/dark/amex.png';

import PeoplePlanetIcon from '@fe/public/icons/peoplePlanet';
import HamburgerIcon from '@fe/public/icons/hamburger';
import ArrowIcon from '@fe/public/images/allplants/arrow';
import AccountIcon from '@fe/public/images/allplants/account';
import CardIcon from '@fe/public/images/allplants/card';
import QuestionIcon from '@fe/public/images/allplants/question';
import PasswordIcon from '@fe/public/images/allplants/password';
import PromiseIcon from '@fe/public/images/allplants/promise';
import VanIcon from '@fe/public/images/allplants/van';
import CalendarIcon from '@fe/public/images/allplants/calendar';
import TickIcon from '@fe/public/images/allplants/tick';
import CrossIcon from '@fe/public/images/allplants/cross';
import InfoIcon from '@fe/public/images/allplants/info';

export const Hamburger = styled(HamburgerIcon)`
  width: 30px;
  height: 20px;
  color: black;
  cursor: pointer;
`;
export const Tick = styled(TickIcon)`
  width: 25px;
  height: 21px;
`;
export const Cross = styled(CrossIcon)`
  width: 30px;
  height: 31px;
`;
export const Info = styled(InfoIcon)`
  width: 20px;
  height: 20px;
  color: ${(p) => p.theme.color.lightGrey};
`;
export const Calendar = styled(CalendarIcon)`
  width: 18px;
  height: 21px;
`;

export const PeoplePlanet = styled(PeoplePlanetIcon)`
  width: 66px;
  height: 66px;
  color: black;
`;

const PayPalLogo = styled.img`
  height: 16px;
  width: 62.22px;
`;
export const PaypalIcon: React.SFC = (props) => (
  <PayPalLogo {...props} src={PaypalIconSrc} />
);

const directionDegrees = {
  left: '90deg',
  right: '270deg',
  up: '-180deg',
  down: '0deg',
};
export interface IArrow {
  direction?: 'left' | 'right' | 'up' | 'down';
  disableAnimation?: boolean;
  isOpened?: boolean;
}
export const ArrowContainer = styled.div<IArrow>`
  color: black;
  width: 22px;
  height: 13px;
  svg {
    ${(p) => !p.disableAnimation && css`transition: transform 0.4s ease-out`};
    transform: ${(p) => p.isOpened ? 'rotate(180deg);' : 'rotate(0deg)'};
    transform: ${(p) => `rotate(${directionDegrees[p.direction || 'down']})`};
  }
`;

export const Arrow: React.SFC<IArrow> = (props) => (
  <ArrowContainer {...props}>
    <ArrowIcon />
  </ArrowContainer>
);

const PaymentLogo = styled.img`
  width: 24px;
  height: 16px;
`;
export const Diners =
  (props) => <PaymentLogo {...props} src={DinersIconSrc} />;
export const Discover =
  (props) => <PaymentLogo {...props} src={DiscoverIconSrc} />;
export const Jcb =
  (props) => <PaymentLogo {...props} src={JcbIconSrc} />;
export const Mastercard =
  (props) => <PaymentLogo {...props} src={MastercardIconSrc} />;
export const Amex =
  (props) => <PaymentLogo {...props} src={AmexIconSrc} />;
export const Visa =
  (props) => <PaymentLogo {...props} src={VisaIconSrc} />;

export {
  AccountIcon,
  PasswordIcon,
  VanIcon,
  CardIcon,
  QuestionIcon,
  PromiseIcon,
};
