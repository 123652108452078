import styled, { css } from '@fe/styles';
import * as e from '@fe/components/Elements';
import { SuffixIcon, InputButton, ErrorMessage, ErrorText } from './components';
import Blank from './blank';
import Container from './container';
import Errors from './errors';
import Input from './input';
import Checkbox from './checkbox';
import Radio, { RadioGroup } from './radio';
import Textarea from './textarea';
import Autosuggest from './autosuggest';
// import Select from './select';
import Date from './date';

const Title = styled.h3<{
  hasIcon?: boolean,
}>`
  font-size: 20px;
  font-weight: bold;
  padding: 4px 0 10px 0;
  margin: 0;
  ${(p) => p.hasIcon && css`
    display: flex;
    justify-content: space-between;
  `}
`;
const TitleInfo = styled(e.Icon.Info)`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  position: relative;
  top: 3px;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 16px;
`;
const Cell = styled.div`
  flex: 1;
  margin: 0 8px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;
const Comment = styled.div`
`;
export {
  Container,
  Errors,
  Blank,
  Input,
  Checkbox,
  Radio,
  RadioGroup,
  Textarea,
  Autosuggest,
  Input as Select,
  Date,

  InputButton,

  Title,
  TitleInfo,
  Row,
  Cell,
  Comment,
  ErrorMessage,
  ErrorText,
  SuffixIcon,
};
