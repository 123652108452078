import * as React from 'react';

export default class Question extends React.Component {
  public render = () => {
    return (
        <svg width='20px' height='20px' viewBox='0 0 41 41' version='1.1' xmlns='http://www.w3.org/2000/svg'>
            <g id='Sign-Up' stroke='none' strokeWidth='1' fill='currentColor' fillRule='evenodd'>
                <g id='CheckOut/Desktop/3_BillingAddress' transform='translate(-919.000000, -728.000000)' fillRule='nonzero'>
                    <g id='icon/question' transform='translate(915.867188, 724.859375)'>
                        <path d='M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z M21.858,27.208 C21.498,27.208 21.174,26.92 21.174,26.524 L21.174,25.012 C21.174,21.592 26.178,20.296 26.178,17.776 C26.178,16.48 25.098,15.364 23.298,15.364 C21.102,15.364 20.13,16.768 19.41,17.236 C19.122,17.416 18.87,17.416 18.618,17.2 L16.926,15.544 C16.638,15.292 16.674,14.86 16.926,14.608 C16.926,14.608 19.374,11.44 23.982,11.44 C27.762,11.44 30.966,13.96 30.966,17.488 C30.966,21.844 25.494,23.104 25.494,25.264 L25.494,26.452 C25.494,26.884 25.242,27.208 24.774,27.208 L21.858,27.208 Z M20.346,34.408 C20.346,32.788 21.678,31.42 23.298,31.42 C24.918,31.42 26.286,32.788 26.286,34.408 C26.286,36.028 24.918,37.36 23.298,37.36 C21.678,37.36 20.346,36.028 20.346,34.408 Z' id='Combined-Shape'></path>
                    </g>
                </g>
            </g>
        </svg>
    );
  }
}
