import config from '@fe/config';
import { createCheckoutForm } from './mock';
import { Query, AddressType, Address } from '@graphql/types/client';

const isDevelopment = config.env === `development`;
const autoPopulateCheckout = isDevelopment && config.featureToggle.autoPopulateCheckout;

const nullAddress = (): Address => ({
  __typename: `Address`,
  postcode: ``,
  fullAddress: ``,
  firstName: ``,
  lastName: ``,
  line1: ``,
  line2: ``,
  town: ``,
  county: ``,
});

const client: Query = {
  petal: {
    __typename: `Petal`,
    view: {
      __typename: `PetalView`,
      isMenuOpen: false,
      isAuthOpen: false,
    },
  },
  view: {
    __typename: 'View',
    isCartOpen: false,
    isUpdatingDiscountCode: false,
  },
  order: {
    __typename: 'OrderDetails',
    totalPrice: 0,
    basketPrice: 0,
    totalDiscount: 0,
    discountedPrice: 0,
    deliveryCharge: 0,
    paypalOrderId: null,
    paypalAuthorization: null,
    discount: null,
  },
  checkoutForm: autoPopulateCheckout ? createCheckoutForm() : {
    __typename: 'CheckoutForm',
    account: {
      __typename: 'AccountForm',
      firstName: ``,
      lastName: ``,
      email: ``,
      phoneNo: ``,
      specialInstructions: ``,
      deliveryDate: ``,
      address: nullAddress(),
    },
    payment: {
      __typename: 'PaymentForm',
      nameOnCard: ``,
      address: nullAddress(),
      useAddressType: AddressType.Delivery,
      acceptsMarketing: false,
    },
  },
};
export default client;
