import { IPublicConfig } from '@tandf/config';
let config: IPublicConfig;
if (typeof SERVER !== 'undefined' && SERVER) {
  config = require(`@tandf/config`).default.public;
} else if (typeof TEST !== 'undefined' && TEST) {
  config = CONFIG;
} else {
  config = (window as any).__CONFIG__;
}

export default config;
