import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import * as React from "react";
import Imgix from "react-imgix";

export default function LazyImage({
  disableLazyload,
  sizes = `100vw`,
  ...restProps
}: {
  disableLazyload?: boolean,
  } | any) {
  if (!restProps.src) {
    // tslint:disable-next-line: no-console
    console.warn(`image was not defined`);
    return null;
  }
  const props = {
    sizes,
    ...restProps,
  };
  if (!disableLazyload) {
    props.attributeConfig = {
      src: `data-src`,
      srcSet: `data-srcset`,
      sizes: `data-sizes`,
    };
    props.className = `lazyload ${props.className}`;
  }
  return (
    <Imgix
      {...props}
    />

  );
}
