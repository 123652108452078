import gql from 'graphql-tag';
import * as fragment from '@fe/graphql/state/fragments';

import { IOrderDetails } from '$/graphql';
import { ApolloCache } from 'apollo-cache';
import { calculateDiscount } from '@fe/graphql/services/discount';
import updateCache from '@fe/graphql/services/updateCache';

export interface IOrderQuery {
  order: Partial<IOrderDetails>;
}
export const updateOrder = (cache: ApolloCache<IOrderQuery>) => (data: IOrderQuery): IOrderQuery => {
  return updateCache(cache)(gql`
    query UpdateOrderResolver {
      order @client {
        ...FullOrder
      }
    }
    ${fragment.order}
  `, data, {
    updateBeforeWrite: (newData: IOrderQuery): IOrderQuery => {
      const updatedOrder = newData.order;
      return {
        order: calculateDiscount(updatedOrder.basketPrice, updatedOrder.deliveryCharge, updatedOrder.discount),
      };
    },
  });
};
