
import defaults from './defaults';
import resolvers from './resolvers';
import typeDefs from '@graphql/client.schema.graphql';

export {
  defaults,
  typeDefs,
  resolvers,
};
