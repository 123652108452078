import * as React from 'react';

export default class Facebook extends React.Component {
  public render = () => {
    return (
        <svg {...this.props} viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'>
            <title>Facebook</title>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='Facebook' fill='currentColor'>
                    <path d='M22.4738882,4.005356 L19.1137073,4 C15.3434909,4 12.9073974,6.22273971 12.9073974,9.66129126 L12.9073974,12.2723409 L9.52761925,12.2723409 C9.23667492,12.2723409 9,12.4798859 9,12.7396519 L9,16.5223264 C9,16.7794143 9.23667492,16.9896373 9.52761925,16.9896373 L12.9073974,16.9896373 L12.9073974,26.5313501 C12.9073974,26.789777 13.1425649,27 13.4350167,27 L17.8383762,27 C18.1278131,27 18.364488,26.789777 18.364488,26.5313501 L18.364488,16.9896373 L22.3125875,16.9896373 C22.6065468,16.9896373 22.8417142,16.7794143 22.8417142,16.5223264 L22.8432217,12.7396519 C22.8432217,12.6151249 22.7874448,12.4972929 22.6879509,12.4075799 C22.588457,12.3192059 22.4542909,12.2723409 22.3156025,12.2723409 L18.364488,12.2723409 L18.364488,10.0576352 C18.364488,8.99446935 18.6524174,8.45619142 20.2126629,8.45619142 L22.4738882,8.45351342 C22.7648326,8.45351342 23,8.24462945 23,7.98486348 L23,4.47534494 C23,4.21557897 22.7648326,4.006695 22.4738882,4.005356' id='Fill-1'></path>
                </g>
            </g>
        </svg>
    );
  }
}
