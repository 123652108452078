import styled from '@fe/styles/styledComponents';

export const hr = styled.hr`
  border: none;
`;

export const BasicDivider = styled(hr)`
  width: 100%;
  background-color: #000;
  height: 1px;
  margin: 12px 0;
`;
export const LightDivider = styled(BasicDivider)`
  opacity: 0.2;
`;
