import styled, { css } from '@fe/styles';
import * as Button from './button';

export const Basic = styled.a<{
  color?: string;
  isNotDecorated?: boolean;
}>`
  cursor: pointer;
  text-decoration: underline;
  color: ${(p) => p.color || p.theme.color.link.default};
  ${(p) =>
    p.isNotDecorated &&
    css`
      text-decoration: none;
    `};
`;

export const Blank = Button.Blank.withComponent(`a`);

export default Basic;
