import styled from '@fe/styles';
import CxLogo from '@fe/public/logos/CX-logo.svg';

import * as e from '@fe/components/Elements';

const headerHeight = 68;
export const HeaderBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${headerHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  background: rgba(255,255,255,0.95);
  z-index: ${(p) => p.theme.depth.front};
  ${(p) => p.theme.layout.paddingResizeCss};
`;
export const LogoContainer = styled.div`
  flex: 1;
`;
export const Logo = styled(CxLogo)`
  width: 150px;
`;
export const ButtonsContainer = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  & > button {
    margin-bottom: 0;
    margin-left: ${(p) => p.theme.layout.spacingHorizontal};
  }
`;

export const HeaderSpacing = styled.div`
  width: 100%;
  height: ${headerHeight}px;
`;

export const TryNowButton = styled(e.Button.default)`
  display: none;
  ${(p) => p.theme.media.phablet`
    display: inline-block;
  `}
`;
