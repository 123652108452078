import * as React from 'react';
import Modal from './base';
import * as e from '@fe/components/Elements';
import * as Form from '@fe/components/Form';
import { setParam } from '@fe/views/common/routeHandler';
import * as yup from 'yup';
import { join } from '@fe/helpers/client/cx';

const RegisterSchema = yup.object().shape({
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export default class RegisterModal extends Modal<{}, {
  isFailed: boolean;
  isSuccessful: boolean;
}> {

  public state = {
    isFailed: false,
    isSuccessful: false,
  };

  public renderSuccess = () => {
    return (
      <>
        <p>
          Thanks for registering your interest!
        </p>
        <p>
          We will be in touch when we have when we are ready to onboard you.
        </p>
      </>
    )
  }

  public renderForm = () => {
    return (
      <>
        <p>
          We are running a closed beta right now.
        </p>
        <p>
          Register your interest and we will let you know when we have accepted your application.
        </p>
        <Form.Container
          name={`register`}
          validationSchema={RegisterSchema}
          onSubmit={async (values: any) => {
            try {
              console.log('submitting', values)
              this.setState({
                isFailed: false,
                isSuccessful: false,
              });
              await join(values);
              console.log(`success`)
              this.setState({
                isSuccessful: true,
              });
            } catch (e) {
              console.log(`fails`, e)
              this.setState({
                isFailed: true,
              });
            }
          }}
        >
          {() => (
            <>
              <Form.Row>
                <Form.Cell>
                  <Form.Input
                    name={`firstName`}
                    label={`First Name`}
                  />
                </Form.Cell>
                <Form.Cell>
                  <Form.Input
                    name={`lastName`}
                    label={`Last Name`}
                  />
                </Form.Cell>
              </Form.Row>
              <Form.Row>
                <Form.Input
                  name={`email`}
                  label={`Email`}
                />
              </Form.Row>
              <e.Button.Basic
                type={`submit`}
              >
                Submit
              </e.Button.Basic>
            </>
          )}
        </Form.Container>
        <p>
          or if you already have an account <a style={{textDecoration: `underline`}} onClick={() => setParam(`auth`, `login`)}>login here</a>.
        </p>
      </>
    )
  }

  public renderContent = () => {
    const {
      isSuccessful,
    } = this.state;
    return (
      <>
        <e.Text.h3>Register</e.Text.h3>
        {
          isSuccessful
            ? this.renderSuccess()
            : this.renderForm()
        }
      </>
    );
  }
}
