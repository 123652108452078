import * as React from 'react';
import styled from '@fe/styles';

const Message = styled.div`
  opacity: 0.8;
  background: ${(p) => p.theme.color.primary};
  color: ${(p) => p.theme.color.primaryComplementary};
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${(p) => p.theme.layout.padding};
  white-space: nowrap;
  overflow-x: auto;
  z-index: ${(p) => p.theme.depth.front};
  cursor: pointer;
`;

export default class DevMessage extends React.Component<{
  children: string | JSX.Element,
}, {
  isDismissed: boolean,
}> {
  public state = {
    isDismissed: false,
  };
  public render = () => {
    if (this.state.isDismissed) return null;
    return (
      <Message onClick={() => this.setState({ isDismissed: true })}>{this.props.children}</Message>
    );
  }
}
