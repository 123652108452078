import styled, { css } from '@fe/styles/styledComponents';

const ParagraphSize = {
  large: css`
    font-size: 24px;
    line-height: 27px;
  `,
  medium: css`
    font-size: 20px;
    line-height: 24px;
  `,
  small: css`
    font-size: 18px;
    line-height: 21px;
  `,
};

export interface IBaseText {
  isCentered?: boolean;
  variant?: `primary` | `secondary`;
}
const variantStyle = {
  primary: css`
    color: ${(p) => p.theme.color.text};
  `,
  secondary: css`
    color: white;
  `,
};
const baseText = css<IBaseText>`
  font-family: ${(p) => p.theme.font.sans};
  font-weight: 400;
  margin: 0 0 1.5em;
  padding: 0;
  ${(p) => p.isCentered && css`
    width: 100%;
    text-align: center;
  `}
  ${(p) => variantStyle[p.variant || `primary`]}
`;
// tslint:disable-next-line:no-empty-interface
export interface IBaseHeader extends IBaseText {}
const baseHeader = css<IBaseHeader>`
  ${baseText}
  margin: 0.5em 0;
`;

const isBold = css<{
  isBold: boolean;
}>`
  ${(p) =>
    p.isBold &&
    css`
      font-weight: bold;
    `};
`;
const noSpacing = css<{
  noSpacing: boolean;
}>`
  ${(p) =>
    p.noSpacing &&
    css`
      margin-bottom: 0 !important;
    `};
`;

export const Regular = styled.div<{
  isBold?: boolean;
  noSpacing?: boolean;
}>`
  ${baseText}
  font-size: 16px;
  ${isBold};
  ${noSpacing};
`;
export const Paragraph = styled.p<{
  isBold?: boolean;
  noSpacing?: boolean;
  size?: 'large' | 'small';
}>`
  ${(p) => ParagraphSize[p.size || 'medium']};
  ${(p) =>
    p.noSpacing
      ? css`
          margin: 0;
        `
      : css`
          margin: 0 0 8px;
        `}
  ${isBold}
`;

export const Title = styled.div`
  ${baseText}
  font-size: 18px;
`;

export const Header = styled.div`
  ${baseText}
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;

  ${(p) => p.theme.media.desktop`
    font-size: 34px;
  `};
`;

export const FormTitleText = styled.h3`
  font-size: 20px;
  width: 100%;
  font-weight: bold;
  margin: 0;
`;

export const FormHelpText = styled(Title)`
  font-weight: 300;
  color: ${(p) => p.theme.color.input.placeholder};
`;

export const h1 = styled.h1<IBaseHeader>`
  ${baseHeader}
  font-weight: 400;
  font-size: 40px;
  letter-spacing: -1.78px;
  ${(p) => p.theme.media.phablet`
    font-weight: 400;
    font-size: 54px;
    line-height: 64px;
  `}
`;
export const h2 = styled.h2<IBaseHeader>`
  ${baseHeader}
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.78px;
  ${(p) => p.theme.media.phablet`
    font-weight: 400;
    font-size: 54px;
    line-height: 64px;
  `}
`;
export const h3 = styled.h3<IBaseHeader>`
  ${baseHeader}
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${(p) => p.theme.color.green};
  ${(p) => p.theme.media.tablet`
    font-size: 32px;
    line-height: 36px;
  `}
`;
export const h4 = styled.h4<IBaseHeader>`
  ${baseHeader}
  font-size: 18px;
  line-height: 24px;
  ${(p) => p.theme.media.tablet`
    font-size: 24px;
    line-height: 30px;
  `}
`;
const pVariant = {
  primary: css`
    color: ${(p) => p.theme.color.text};
  `,
  secondary: css`
    color: white;
  `,
};
export const P = styled.p<IBaseHeader>`
  ${baseText}
  ${(p) => pVariant[p.variant || `primary`]}
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: -0.2px;
  ${(p) => p.theme.media.tablet`
    font-size: 21px;
    line-height: 29px;
  `}
`;
export const PSmall = styled.p<IBaseHeader>`
  ${baseText}
  ${(p) => pVariant[p.variant || `primary`]}
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: -0.2px;
  ${(p) => p.theme.media.tablet`
    font-size: 18px;
    line-height: 25px;
  `}
`;
