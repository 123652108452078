import styled, { css } from '@fe/styles/styledComponents';

export interface IButton {
  disabled?: boolean;
  isBlock?: boolean;
  isCentered?: boolean;
  isLarge?: boolean;
  isTopMargin?: boolean;
}

const base = styled.button<IButton>`
  cursor: pointer;
  outline: none;
  border: none;
  font-family: ${(p) => p.theme.font.sans};
  text-transform: uppercase;
  font-weight: bold;
  transition: ${(p) => p.theme.transition.default};
  ${(p) => p.disabled && css`
    background-color: red;
    color: white;
  `}
  ${(p) => p.isBlock && css`
    width: 100%;
    text-align: center;
  `}
  font-size: 12px;
  ${(p) => p.isLarge && css`
    font-size: 16px;
  `};
  ${(p) => p.isCentered && css`
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const Input = styled(base)<IButton>`
  margin-bottom: 12px;
  padding: 12px;
  background-color: ${(p) => p.theme.color.button.active};
  color: black;
  ${(p) => p.disabled && css`
    background-color: ${p.theme.color.button.disabled};
    color: white;
    pointer-events: none;
  `}
`;

const BasicButtonVariant = {
  primary: css<IButton>`
    background-color: ${(p) => p.theme.color.button.active};
    color: white;
    ${(p) => p.disabled && css`
      background-color: ${p.theme.color.button.disabled};
      color: white;
    `}
  `,
  secondary: css<IButton>`
    background-color: transparent;
    color: black;
    border: 1px solid #E3E3E3;
    :hover {
      background-color: #FFFFFF;
    }
    ${(p) => p.disabled && css`
      color: rgba(0, 0, 0, 0.6);
    `}
  `,
};
export const Basic = styled(base) <{
  variant?: `primary` | `secondary`,
  isCondensed?: boolean,
} & IButton>`
  margin-bottom: 12px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 20px;
  white-space: nowrap;
  transition: ${(p) => p.theme.transition.default}
  ${(p) => p.disabled && css`
    pointer-events: none;
  `}
  ${(p) => p.theme.media.tablet`
    font-size: 13px;
    line-height: 16px;
    padding: 12px 20px;
  `}
  ${(p) => BasicButtonVariant[p.variant || `primary`]}
`;

export const PaymentButton = styled(base)<IButton>`
  width: 120px;
  padding: 9px 28px;
  background-color: ${(p) => p.theme.color.button.active};
`;

export const FormButton = styled(Basic)<IButton>`
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
`;

export const OutlineButton = styled(base)<IButton>`
  display: flex;
  min-width: 72px;
  font-size: 12px;
  align-self: baseline;
  justify-content: center;
  border: 2px solid ${(p) => p.theme.color.button.active};
  color: #000000;
  letter-spacing: 0.5px;
  padding: 4px 8px;
  ${(p) => p.isTopMargin && css`
    margin-top: 6px;
  `};
  background-color: ${(p) => p.theme.color.page};
`;

export const TextButton = styled(base)<IButton>`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  ${(p) => p.theme.media.tablet`
    font-size: 16px;
    line-height: 24px;
  `}
  &:hover {
    text-decoration: underline;
  }
`;

export const Link = styled.a<IButton>`
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 16px;
  color: inherit;
  font-family: ${(p) => p.theme.font.sans};
  text-decoration: none;
  background: transparent;
  &:hover {
    text-decoration: underline;
  }
`;

export const Blank = styled.button`
  cursor: pointer;
  outline: none !important;
  border: none;
  background: transparent;
`;

export default Basic;
