import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SchemaLink as ApolloSchemaLink } from 'apollo-link-schema';
import { makeExecutableSchema, addMockFunctionsToSchema } from 'graphql-tools';
// import cmsSchema from '@graphql/cms.schema.graphql';
import serverSchema from '@graphql/server.schema.graphql';

// import { importSchema } from 'graphql-import';

// const cmsTypeDefs = importSchema('graphql/cms.schema.graphql');

import mocks from './mocks';

const cache = new InMemoryCache();

const schema = makeExecutableSchema({
  typeDefs: serverSchema as any,
  resolverValidationOptions: {
    requireResolversForResolveType: false,
  },
  // resolvers: {
  //   Query,
  // },
});

// addMockFunctionsToSchema({
//   mocks,
//   schema,
// });

export const SchemaLink =  new ApolloSchemaLink({ schema });

export default new ApolloClient({
  cache,
  link: SchemaLink,
});
