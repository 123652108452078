import 'objectFitPolyfill';
import * as React from 'react';
import styled from '@fe/styles/styledComponents';
import ResponsiveImage from '@fe/components/responsiveImage';

export const img = styled(ResponsiveImage)``;

export const SmallSquareImg = styled((props) => <ResponsiveImage size={`74`} {...props} />)`
  width: 48px;
  height: 48px;

  ${(p) => p.theme.media.desktop`
    width: 74px;
    height: 74px;
  `}
`;

const CoverContainer = styled.div`
  position: relative;
`;
export const CoverImageContainer = styled.div`
  position: absolute !important;
  width: 100%;
  height: 100%;
`;
export const CoverImage = styled(ResponsiveImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
export const CoverChildren = styled.div`
  position: relative;
`;
export const Cover: React.SFC<{
  src: string,
  alt: string,
  children: JSX.Element,
  className?: string,
}> = ({ src, alt, className, children, ...rest }) => (
  <CoverContainer {...rest}>
    <CoverImageContainer>
      <CoverImage data-object-fit src={src} alt={alt} />
    </CoverImageContainer>
    <CoverChildren className={className}>
      {children}
    </CoverChildren>
  </CoverContainer>
);

export const Responsive = styled(ResponsiveImage)``;
