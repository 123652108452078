import * as React from 'react';

import * as c from './components';
import InputWrapper from './inputWrapper';
// import withInfo from './field';
import { connect } from 'formik';

@(connect)
export default class InputComponent<T> extends InputWrapper<T> {
  protected renderInput = () => {
    const {
      label,
      placeholder,
      iconEnd,
      onChange,
      refLink,
      disabled,
      // Take this off rest,
      ...rest
    } = this.props;

    return (
      <c.InputContainer
        isValid={this.isValid}
        isDirty={this.isDirty}
        isFocused={this.isFocused}
        disabled={!!disabled}
      >
        <c.Input
          {...rest}
          disabled={disabled}
          name={this.name}
          innerRef={refLink}
          isValid={this.isValid}
          isShowingLabel={!this.isEmpty}
          placeholder={placeholder === undefined ? label : placeholder}
        />
      </c.InputContainer>
    );
  }
}
