import styled, { css, defaults } from '@fe/styles';

import * as e from '@fe/components/Elements';
import * as c from './components';

export const Container = styled(e.Collapse.CollapseContent)`
`;
export const Content = styled.div`
  margin: 0 auto;
  padding: ${(p) => p.theme.layout.padding};
  overflow-y: scroll;
`;
export const List = styled.ul`
  margin: 0;
  padding-left: ${(p) => p.theme.layout.spacingHorizontal};
`;
export const ErrorText = c.ErrorText;
export const Item = styled(c.ErrorText.withComponent(`li`))`
`;
