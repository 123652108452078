
import config from '@fe/config';
import axios from 'axios';

export interface ISentryFeedback {
  comments?: string;
  email: string;
  name: string;
}

const sentryServer = axios.create({
  baseURL: 'https://sentry.io',
  headers: {
    Authorization: `Bearer ${config.sentry.token}`,
    'content-Type': 'application/json',
  },
});
const feedbackApi = `/api/0/projects/${config.sentry.org}/${config.sentry.project}/user-feedback/`;

export const sendFeedback = async (eventId: string, feedback: ISentryFeedback): Promise<any> => {
  return sentryServer.post(feedbackApi, {
    ...feedback,
    event_id: eventId,
  });
};
