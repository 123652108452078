import gql from 'graphql-tag';

export const view = gql`
  fragment FullView on View {
    __typename
    isCartOpen
    isUpdatingDiscountCode
  }
`;
export const address = gql`
  fragment FullAddress on Address {
    __typename
    fullAddress
    firstName
    lastName
    line1
    line2
    town
    county
    postcode
  }
`;
export const accountForm = gql`
  fragment FullAccountForm on AccountForm {
    __typename
    firstName
    lastName
    email
    phoneNo
    deliveryDate
    specialInstructions
    address {
      ...FullAddress
    }
  }
  ${address}
`;
export const deliveryForm = gql`
  fragment FullDeliveryForm on DeliveryForm {
    __typename
    deliveryDate
    specialInstructions
    address {
      ...FullAddress
    }
  }
  ${address}
`;
export const paymentForm = gql`
  fragment FullPaymentForm on PaymentForm {
    __typename
    nameOnCard
    useAddressType
    acceptsMarketing
    address {
      ...FullAddress
    }
  }
  ${address}
`;
export const checkoutForm = gql`
  fragment FullCheckoutForm on CheckoutForm {
    __typename
    account {
      ...FullAccountForm
    }
    payment {
      ...FullPaymentForm
    }
  }
  ${accountForm}
  ${deliveryForm}
  ${paymentForm}
`;
export const discount = gql`
  fragment FullDiscount on Discount {
    __typename
    amount
    code
    discountType
  }
`;
export const order = gql`
  fragment FullOrder on OrderDetails {
    __typename
    totalPrice
    basketPrice
    discountedPrice
    totalDiscount
    deliveryCharge
    paypalAuthorization
    paypalOrderId
    discount {
      ...FullDiscount
    }
  }
  ${discount}
`;
