import * as React from 'react';

import flatten from 'flat';
import { sentence } from 'change-case';
import { connect, FormikContext, FormikErrors } from 'formik';
import withSizes, { ISizes } from '@fe/components/Size';

import * as e from '@fe/components/Elements';
import * as c from './errors.components';

export class Errors extends React.Component<{
  children: JSX.Element,
  label?: string,
  formik: FormikContext<any>,
  sizes: ISizes,
}> {
  public static defaultProps = {
    label: `please complete the following fields:`,
  };

  private getErrorMessages = (errors: FormikErrors<any>) => {
    const flatErrors = flatten(errors, { delimiter: `_` });
    return Object.keys(flatErrors)
      .map((key) => ({
        label: sentence(key),
        value: flatErrors[key],
      }));
  }
  public render = () => {
    const {
      label,
      formik,
      children,
      sizes,
    } = this.props;

    const errorCount = Object.keys(formik.errors).length;
    const hasErrors = errorCount > 0;
    return (
      <e.Tooltip.default
        position={`top`}
        open={hasErrors ? undefined : false}
        trigger={`click`}
        html={(
          <c.Content>
            {sizes.greaterThan.phablet ? (
              <>
                <c.ErrorText>{label}</c.ErrorText>
                <c.List>
                  {this.getErrorMessages(formik.errors)
                    .map((messages) => (
                      <c.Item key={messages.label}>{`${messages.label}: ${messages.value}`}</c.Item>
                    ))}
                </c.List>
              </>
            ) : (
                <c.ErrorText>please fix the {errorCount} error{errorCount > 1 && `s`} in the form before continuing</c.ErrorText>
            )}
          </c.Content>
        )}
      >
        {children}
      </e.Tooltip.default>
    );
  }
}

export default connect(withSizes()(Errors)) as React.SFC;
