import * as React from 'react';

import InputWrapper from './inputWrapper';
import { InputContainerCss, InputTextCss, IInputContainer } from './components';
import styled, { css } from 'styled-components';
import { connect, Field } from 'formik';

const TextareaContainer = styled.div<IInputContainer>`
  ${InputContainerCss}
  padding-top: 10px;
  height: auto;
`;
const Textarea = styled.textarea<{
  isShowingLabel: boolean,
}>`
  ${InputTextCss}
  resize: vertical;
  line-height: 26px;
  min-height: 64px;
  display: block;
`;

const TextareaCharactersLength = styled.div`
  position: relative;
  font-size: 16px;
  color: ${(p) => p.theme.color.input.placeholder};
`;

@(connect)
export default class TextareaComponent extends InputWrapper<{
  maxLength?: number,
}> {
  public renderInput = () => {
    const {
      disabled,
      maxLength,
      ...rest
    } = this.props;

    return (
      <>
        <TextareaContainer
          isValid={this.isValid}
          disabled={disabled}
          isFocused={this.isFocused}
          isDirty={this.isDirty}
        >
        <Field
          name={this.name}
          render={({ field }) => (
            <Textarea
              isShowingLabel={!this.isEmpty}
              isValid={this.isValid}
              disabled={disabled}
              {...rest}
              {...field}
            />
          )}
        />
        </TextareaContainer>
        {maxLength && (
          <TextareaCharactersLength>{this.value ? this.value.length : 0}/{maxLength} characters</TextareaCharactersLength>
        )}
      </>
    );
  }
}
