import * as React from 'react';

import styled, { css } from '@fe/styles';
import * as gtm from '@fe/services/gtm';

import * as e from '@fe/components/Elements';
import * as c from './components';
import { InputType } from './types';
import * as get from 'safe-access';

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  font-family: ${(p) => p.theme.font.sans};
`;

export const LoadingContainer = styled.div<{
  isLoading?: boolean,
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 10px;
  text-align: right;
  pointer-events: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255,255,255, 0.6);
  opacity: 0;
  transition: ${(p) => p.theme.transition.default};
  z-index: ${(p) => p.theme.depth.front + 2};
  ${(p) => p.isLoading && css`
    opacity: 1;
    pointer-events: auto;
  `}
`;
const LoadingSpinner = styled(e.Loading.Spinner)`
  transform: scale(0.5);
  color: ${(p) => p.theme.color.lightGrey};
`;

// type OuterProps = IInput & FieldProps;
export default abstract class InputComponent<
  P extends any = {},
  S extends any = {}
> extends React.Component<
  InputType<P>,
  S & {
    isTooltipShowing: boolean,
    isFocused: boolean,
  }> {

  protected get name(): string {
    return this.props.name;
  }
  protected get value(): string {
    return get(this.props.formik.values, this.name);
  }
  protected get isEmpty(): boolean {
    return !this.value;
  }
  protected get isValid(): boolean {
    return !this.errorMessage;
  }
  protected get isDirty(): boolean {
    return get(this.props.formik.touched, this.name);
  }
  protected get isPristine(): boolean {
    return !this.isDirty;
  }
  protected get isFocused(): boolean {
    return this.state.isFocused;
  }
  protected get errorMessage(): string {
    return get(this.props.formik.errors, this.name);
  }
  protected get isDebounced(): boolean {
    return true;
  }
  public static defaultProps = {
    onChange: () => null,
    defaultErrorMessage: `please enter a valid value`,
  };

  protected manuallySetFocusedAndDirty = false;

  protected abstract renderInput: () => JSX.Element;

  constructor(props: any) {
    super(props);
    this.state = {
      ...super.state,
      isFocused: false,
      isTooltipShowing: false,
    };
  }

  public componentDidMount = () => {
    if (this.value) this.props.formik.setFieldTouched(this.name, true);
  }

  public render = () => {
    const {
      isTooltipShowing,
    } = this.state;
    const {
      isLoading,
      label,
      iconEnd,
      isLabelOpaque,
      button,
      iconMessage,
      formik,
    } = this.props;

    return (
      <InputContainer
        onFocus={() => {
          if (!this.manuallySetFocusedAndDirty) {
            this.setState({ isFocused: true });
          }
          // gtm.logEvent(`input.focus`, { name: this.name, value: this.value });
        }}
        onBlur={() => {
          if (!this.manuallySetFocusedAndDirty) {
            this.setState({ isFocused: false });
            formik.setFieldTouched(this.name, true);
          }
          gtm.logEvent(`input.blur`, { name: this.name, value: this.value });
        }}
      >
        <e.Tooltip.default
          position={`bottom`}
          open={isTooltipShowing}
          title={iconMessage}
          disabled={!iconMessage}
        >
        <c.LabelText isOpaque={!!isLabelOpaque} isShowing={!this.isEmpty}>{label}</c.LabelText>
        {this.renderInput()}
        <c.ErrorMessage name={this.name} />
        {iconEnd && (
          <e.Tooltip.default
            open={false}
            onShow={() => this.setState({ isTooltipShowing: true })}
            onHide={() => this.setState({ isTooltipShowing: false })}
          >
            {iconEnd}
          </e.Tooltip.default>
        )}
        {button}
        {isLoading !== undefined && (
          <LoadingContainer isLoading={isLoading}>
            <LoadingSpinner />
          </LoadingContainer>
        )}
        </e.Tooltip.default>
      </InputContainer>
    );
  }
}
