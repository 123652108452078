import flatten from 'flat';

const prefixObject = (obj: any, prefix?: string): any => {
  if (!prefix) return obj;
  const prefixObj: any = {};
  Object.keys(obj).forEach((key) => prefixObj[`${prefix}${key}`] = obj[key]);
  return prefixObj;
};

export const addData = (content: any, prefix?: string) => {
  try {
    if (SERVER) return;
    const data = prefixObject(flatten(content, { delimiter: '_' }), prefix);
    dataLayer.push(data);
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error('Failed to add data for GTM:', e);
  }
};
export const sendEvent = (eventName: string, content: any = {}, doFlatten: boolean = true, prefix?: string) => {
  try {
    if (SERVER) return;
    const processedData = doFlatten ? flatten(content, { delimiter: '_' }) : content;
    const data = {
      ...prefixObject(processedData, prefix),
      event: eventName,
    };
    dataLayer.push(data);
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(`Failed to send event ${eventName} for GTM:`, e);
  }
};
const sentEvents = {};
export const sendEventOnce = (eventName, ...rest) => {
  if (!sentEvents[eventName]) {
    sentEvents[eventName] = true;
    sendEvent(eventName, ...rest);
  }
};
export const sendClick = (name: string, data: any = null) => {
  logEvent(`button.click`, {
    ...data,
    name,
  });
};
export const sendSelect = (type: string, name: string, data: any = null) => {
  logEvent(`select.${type}`, {
    ...data,
    name,
  });
};
export const logEvent = (name: string, data: any = {}) => {
  addData({ log_data: undefined }); // reset data
  sendEvent(`log`, {
    log_name: name,
    log_data: data,
  }, false);
};

export const setUserId = (id: any) => {
  sendEvent(`user.id`, {
    user_id: id,
  }, false);
};
export const setUserData = (data: any) => {
  sendEvent(`user.data`, {
    user_data: data,
  }, false);
};
export const setUserDataOnce = (data: any) => {
  throw new Error('Not implemented in GTM');
  sendEvent(`user.data.once`, {
    user_data: data,
  }, false);
};
export const sendError = (data: any) => {
  logEvent(`error`, data);
};

interface IRevenueData {
  quantity: number;
  price: number;
  type: string;
  productId: string;
  eventProperties?: any;
}
export const trackRevenue = (data: Array<IRevenueData>) => {
  data.forEach((item) => {
    sendEvent(`revenue`, {
      revenue_data: item,
    }, false);
  });
};
