import * as React from 'react';
import { Collapse } from 'react-collapse';
import styled from '@fe/styles/styledComponents';

export interface ICollapseProps {
  children: JSX.Element | Array<JSX.Element>;
  isOpened: boolean;
  onMeasure?: (props?: any) => void;
  onRest?: () => void;
  removeWhenClosed?: boolean;
}

const StyledCollapseContent = styled(Collapse as any)`
  width: 100%;
  /* Disable margin collapsing */
  > .ReactCollapse--content {
    display: inline-block;
    width: 100%;
  }
`;
export class CollapseContent extends React.Component<ICollapseProps, {
  isAnimating: boolean,
}> {
  public state = {
    isAnimating: false,
  };

  public render = () => {
    const {
      isAnimating,
    } = this.state;
    const {
      isOpened,
      removeWhenClosed,
    } = this.props;
    const {
      onRest,
      onMeasure,
      ...collapseProps
    } = this.props;
    if (!isAnimating && !isOpened && removeWhenClosed) {
      return null;
    } else {
      return (
        <StyledCollapseContent
          onRest={() => {
            if (this.state.isAnimating) {
              this.setState({ isAnimating: false });
            }
            if (onRest) onRest();
          }}
          onMeasure={(p) => {
            if (!this.state.isAnimating) {
              this.setState({ isAnimating: true });
            }
            if (onMeasure) onMeasure(p);
          }}
          {...collapseProps}
        />
      );
    }
  }
}
