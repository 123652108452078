import gql from 'graphql-tag';
import * as fragment from '../../../fragments';

import { Apollo } from '$/apollo';
import { IDiscount, ICheckoutForm, IOrderDetails, IView } from '$/graphql';

import { updateOrder } from '../common/order';
import * as gtm from '@fe/services/gtm';
import updateCache from '@fe/graphql/services/updateCache';
import * as cookies from '@fe/services/cookieManager';
import config from '@fe/config';

export const onPaypalAuthorization: Apollo.MutationResolver<{ authorization: string, form: ICheckoutForm, orderId: string }>
  = (_, { form, orderId = null, authorization = null }, { cache }) => {
    return updateCache<{
      order: Partial<IOrderDetails>,
      checkoutForm: Partial<ICheckoutForm>,
    }>(cache)(gql`
      query GetCheckoutForm {
        order @client {
          paypalAuthorization
          paypalOrderId
        }
        checkoutForm @client {
          ...FullCheckoutForm
        }
      }
      ${fragment.checkoutForm}
    `, {
      order: {
        paypalAuthorization: authorization,
        paypalOrderId: orderId,
      },
      checkoutForm: form,
    });
  };
export const updateBasketPrice = (_, { basketPrice }, { cache }) => {
  return updateOrder(cache)({
    order: {
      basketPrice,
      totalPrice: basketPrice,
      discountedPrice: basketPrice,
    },
  });
};
export const updateAccountForm = (_, { account, deliveryCharge }, { cache }) => {
  gtm.sendEvent('accountForm', { account }, false);
  const newData = updateCache<{ checkoutForm: Partial<ICheckoutForm>}>(cache)(gql`
    query AccountFormUpdate {
      checkoutForm @client {
        account {
          ...FullAccountForm
        }
      }
    }
    ${fragment.accountForm}
  `, {
    checkoutForm: {
      account,
    },
  });
  cookies.merge(config.cookieKey, newData);

  if (deliveryCharge !== undefined) {
    updateOrder(cache)({
      order: {
        deliveryCharge,
      },
    });
  }

  return newData;
};
export const updatePaymentForm = (_, { form }, { cache }) => {
  gtm.sendEvent('paymentForm', { payment: form }, false);
  const newData = updateCache<{ checkoutForm: Partial<ICheckoutForm> }>(cache)(gql`
    query DeliveryFormUpdate {
      checkoutForm @client {
        payment {
          ...FullPaymentForm
        }
      }
    }
    ${fragment.paymentForm}
  `, {
    checkoutForm: { payment: form },
  });
  cookies.merge(config.cookieKey, newData);
  return newData;
};
export const updateView = (_, { view }, { cache }) => {
  const newData = updateCache<{ view: IView }>(cache)(gql`
    query ViewUpdate {
      view @client {
        ...FullView
      }
    }
    ${fragment.view}
  `, {
    view,
  }, {
    updateBeforeWrite: (data) => {
      if (data.view.isUpdatingDiscountCode) data.view.isCartOpen = true;
      else if (!data.view.isCartOpen) data.view.isUpdatingDiscountCode = false;
      return data;
    },
  });
  cookies.merge(config.cookieKey, newData);
  return newData;
};
export const applyDiscount: Apollo.MutationResolver<{ discount: IDiscount }>
  = (_, { discount }, { cache }) => {
    const newData = updateOrder(cache)({
      order: {
        discount,
      },
    });

    return newData;
  };
