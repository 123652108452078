import { IDiscount, IOrderDetails } from '$/graphql';

export const calculateDiscount = (basketPrice: number, deliveryCharge: number , discount?: IDiscount): IOrderDetails => {
  const totalPrice = basketPrice + deliveryCharge;
  let totalDiscount = 0;
  let discountedPrice = totalPrice;
  if (discount) {
    if (discount.discountType === 'FIXED_AMOUNT') {
      discountedPrice = totalPrice - discount.amount;
      totalDiscount = discount.amount;
    } else if (discount.discountType === 'PERCENTAGE') {
      discountedPrice = Math.round(totalPrice * ((100 - discount.amount) / 100));
      totalDiscount = totalPrice - discountedPrice;
    }
  }
  return {
    totalPrice,
    basketPrice,
    totalDiscount,
    discountedPrice,
    deliveryCharge,
    discount,
  };
};
