import styled, { css } from '@fe/styles';

export const CenteredPage = styled.div<{
  children: JSX.Element | Array<JSX.Element>;
  removePadding?: boolean;
}>`
  max-width: ${(p) => p.theme.layout.maxWidth};
  margin: 0 auto;
  padding: ${(p) => p.theme.layout.padding};
  position: relative;
  ${(p) => p.removePadding && `padding: 0;`}
`;

export const HeaderContainer = styled.div``;

export const PageContainer = styled.div<{
  fixedHeight?: boolean,
}>`
  background: ${(p) => p.theme.color.backgroundGrey};
  max-width: 100%;
  ${(p) => p.fixedHeight
    ? css`
      height: 100vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    ` : css`
      flex: 1;
    `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowCenterAligned = styled(Row)`
  align-items: center;
`;

export const RowCenterJustified = styled(Row)`
  justify-content: center;
`;

export const CenterContainer = styled.div`
  margin: 0 auto;
  max-width: ${(p) => p.theme.layout.centerBlockMaxWidth};
`;

export const SectionContainer = styled.div<{
  noBackgroundColor?: boolean;
}>`
  margin: ${(p) => p.theme.layout.margin} 0;
  background-color: ${(p) => p.noBackgroundColor ? 'unset' : p.theme.color.page};
`;
export const ContentContainer = styled.div`
  ${(p) => p.theme.layout.paddingResizeCss}
`;
export const InnerSectionContainer = styled(ContentContainer)`
  margin-bottom: 24px;
  background-color: ${(p) => p.theme.color.backgroundLightGrey};
  :before,
  :after {
    content: " ";
    display: table;
  }
`;
