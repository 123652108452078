// Apollo GraphQL client

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import { InMemoryCache, NormalizedCacheObject, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { resolvers, defaults, typeDefs } from './state';
import { setContext } from 'apollo-link-context';
import { SchemaLink } from '@graphql/mock/client';
import introspectionQueryResultData from '@graphql/generated/fragmentTypes.json';

import { Context } from 'koa';
import { parse } from 'query-string';
import * as deepmerge from 'deepmerge';

/* Local */
import config from '@fe/config';

// Required for legacy browsers like iOS 10 safari
global.fetch = global.fetch || require('unfetch').default;

const {
  disableCookieHydration,
} = config.featureToggle;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// ----------------------------------------------------------------------------

export async function createClient(cookieKey: string, ctx?: Context): Promise<ApolloClient<NormalizedCacheObject>> {

  // Create the cache first, which we'll share across Apollo tooling.
  // This is an in-memory cache. Since we'll be calling `createClient` on
  // universally, the cache will survive until the HTTP request is
  // responded to (on the server) or for the whole of the user's visit (in
  // the browser)
  const cache = new InMemoryCache({ fragmentMatcher });

  let initData = defaults;
  try {
    if (!disableCookieHydration) {
      if (SERVER) {
        const cookies = require('cookies')(ctx!.req, ctx!.res);
        const storedData = cookies.get(cookieKey);
        if (storedData) {
          initData = deepmerge(initData, JSON.parse(decodeURIComponent(storedData)));
        }
      } else {
        const cookies = require('cookies-js');
        const storedData = cookies.get(cookieKey);
        if (storedData) {
          initData = deepmerge(initData, JSON.parse(storedData));
        }
      }
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(`error initialising from cookie`, e);
    initData = defaults;
  }
  cache.writeData({
    data: initData,
  });

  // If we're in the browser, we'd have received initial state from the
  // server. Restore it, so the client app can continue with the same data.
  if (!SERVER) {
    cache.restore((window as any).__APOLLO_STATE__);
  }

  // Return a new Apollo Client back, with the cache we've just created,
  // and an array of 'links' (Apollo parlance for GraphQL middleware)
  // to tell Apollo how to handle GraphQL requests
  return new ApolloClient({
    cache,
    resolvers,
    typeDefs,
    link: ApolloLink.from([
      // General error handler, to log errors back to the console.
      // Replace this in production with whatever makes sense in your
      // environment. Remember you can use the global `SERVER` variable to
      // determine whether you're running on the server, and record errors
      // out to third-party services, etc
      onError(({ operation, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path, ...rest }) => {
          // tslint:disable-next-line:no-console
            console.log(
              `[GraphQL error]: For query ${path}:\n${message}\nLocation: ${JSON.stringify(locations)}\nVariables:`,
              operation.variables,
              rest,
            );
          });
        }
        if (networkError) {
          // tslint:disable-next-line:no-console
          console.log(`[Network error]: For: ${JSON.stringify(operation.query.loc, null, 2)}\nError is: ${JSON.stringify(networkError, null, 2)}`);
        }
      }),
      setContext((request, _previousCountext) => {
        return request;
      }),

      config.graphql.useMock
        ? SchemaLink
        : new HttpLink({
          uri: config.graphql.url,
          credentials: 'same-origin',
          headers: ctx ? ctx.req.headers : {},
        }),

    ]),
    // On the server, enable SSR mode
    ssrMode: SERVER,
  });
}
