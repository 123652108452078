import './date.css';
import styled, { css, injectGlobal, defaults } from '@fe/styles';
import * as e from '@fe/components/Elements';
import { InputContainerCss, InputTextCss, IInputContainer } from './components';

export const Hatching = require('!!url-loader!@fe/public/images/allplants/hatching.png');

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  .DateInput_fang {
    z-index: ${defaults.depth.foreground + 1};
  }
  .SingleDatePicker_picker {
    z-index: ${defaults.depth.foreground};
  }
  .DateInput {
    flex: 1;
    width: auto;
    display: flex;
  }
  .CalendarMonth_caption {
    color: #111111;
    font-size: 18px;
    font-weight: 500;
  }
  .CalendarMonth_table {
    border: 3px solid white;
    background-color: #F9F9F8;
  }
  .DayPicker_weekHeader_li {
    font-weight: bold;
    color: black;
  }
  .CalendarDay__default {
    outline: none;
    border: 3px solid white;
    color: black;
    background: ${defaults.color.primary};
    :hover {
      outline: none;
      border: 3px solid white;
      background: ${defaults.color.lightYellow};
    }
  }
  .CalendarDay__highlighted_calendar {
    background-image: url(${Hatching});
  }
  .CalendarDay__blocked_calendar, .CalendarDay__blocked_out_of_range {
    background-image: none;
    background-color: #F3F3F2;
    color: #A0A098;
    :hover {
      background-color: #F3F3F2;
      color: #A0A098;
    }
  }
`;

export const AllplantsStyling = styled.div<{
  value: string,
} & IInputContainer>`
  .SingleDatePicker {
    width: 100%;
  }
  .SingleDatePickerInput__withBorder {
    width: 100%;
    display: flex;
  }
  .SingleDatePickerInput_calendarIcon {
    outline: none;
    z-index: ${defaults.depth.infront + 1};
  }
  .SingleDatePickerInput {
    ${InputContainerCss}
  }
  .DateInput_input {
    ${InputTextCss}
  }
  .DateInput_input__focused {
    border-bottom-color: transparent;
  }
`;
export const Arrow = styled(e.Icon.Arrow) <{
  direction: 'left' | 'right';
}>`
  margin: 0 30px;
  position: absolute;
  top: 25px;
  ${(p) => p.direction === `left` && css`
    left: 0;
  `}
  ${(p) => p.direction === `right` && css`
    right: 0;
  `}
`;

export const Input = styled.input<{
  isValid: boolean,
  value: string,
}>`
  border-color: ${(p) => p.theme.color.lightGrey};
  ${(p) => p.isValid && css`
    border-color: green;
  `}
  outline: none;
  width: 100%;
  height: 56px;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 20px;
  padding-left: 10px;
  padding-top: ${(p) => p.value ? '15px' : '0px'};
  font-weight: normal;
  color: ${(p) => p.theme.color.input.default};
  ::placeholder {
    color: ${(p) => p.theme.color.input.placeholder};
  }
`;

export const Legends = styled.div<{
  isTop: boolean;
}>`
  ${(p) => p.isTop ? css`
    padding-top: 20px;
  ` : css`
    padding-bottom: 16px;
  `}
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
`;
export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
`;
export const LegendColor = styled.div<{
  background: any;
}>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: ${(p) => p.background};
  content: ' ';
  margin-right: 8px;
`;
export const LegendText = styled.div`
`;
