import gql from "graphql-tag";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccountForm = {
  __typename?: "AccountForm";
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNo?: Maybe<Scalars["String"]>;
  specialInstructions?: Maybe<Scalars["String"]>;
  deliveryDate?: Maybe<Scalars["String"]>;
  address?: Maybe<Address>;
};

export type AccountFormInput = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNo?: Maybe<Scalars["String"]>;
  specialInstructions?: Maybe<Scalars["String"]>;
  deliveryDate?: Maybe<Scalars["String"]>;
};

export type Address = {
  __typename?: "Address";
  fullAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  town?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
};

export enum AddressType {
  Billing = "BILLING",
  Delivery = "DELIVERY"
}

export type Cart = {
  __typename?: "Cart";
  token?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  totalPrice?: Maybe<Scalars["Int"]>;
  totalDiscount?: Maybe<Scalars["Int"]>;
  totalWeight?: Maybe<Scalars["Int"]>;
  itemCount?: Maybe<Scalars["Int"]>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  attributes?: Maybe<CartAttributes>;
};

export type CartAttributes = {
  __typename?: "CartAttributes";
  deliveryDate?: Maybe<Scalars["String"]>;
};

export type CheckoutForm = {
  __typename?: "CheckoutForm";
  account?: Maybe<AccountForm>;
  payment?: Maybe<PaymentForm>;
};

export type Discount = {
  __typename?: "Discount";
  amount: Scalars["Int"];
  code: Scalars["String"];
  discountType: DiscountCalculationType;
};

export enum DiscountCalculationType {
  Percentage = "PERCENTAGE",
  FixedAmount = "FIXED_AMOUNT"
}

export type Mutation = {
  __typename?: "Mutation";
  updateAccountForm?: Maybe<CheckoutForm>;
  updatePaymentForm?: Maybe<CheckoutForm>;
  submitCheckoutForm?: Maybe<CheckoutForm>;
  onPaypalAuthorization?: Maybe<Scalars["Boolean"]>;
  updateBasketPrice?: Maybe<Scalars["Boolean"]>;
  updateView?: Maybe<View>;
  updatePetalView?: Maybe<PetalView>;
};

export type MutationUpdateAccountFormArgs = {
  form: AccountFormInput;
};

export type MutationUpdatePaymentFormArgs = {
  form: PaymentFormInput;
};

export type MutationSubmitCheckoutFormArgs = {
  form: CheckoutForm;
};

export type MutationOnPaypalAuthorizationArgs = {
  form: CheckoutForm;
  orderId: Scalars["String"];
  authorization: Scalars["String"];
};

export type MutationUpdateBasketPriceArgs = {
  basketPrice: Scalars["Int"];
};

export type MutationUpdateViewArgs = {
  view: ViewInput;
};

export type MutationUpdatePetalViewArgs = {
  view: PetalViewInput;
};

export type OrderDetails = {
  __typename?: "OrderDetails";
  totalPrice?: Maybe<Scalars["Int"]>;
  basketPrice?: Maybe<Scalars["Int"]>;
  discountedPrice?: Maybe<Scalars["Int"]>;
  totalDiscount?: Maybe<Scalars["Int"]>;
  deliveryCharge?: Maybe<Scalars["Int"]>;
  paypalOrderId?: Maybe<Scalars["String"]>;
  paypalAuthorization?: Maybe<Scalars["String"]>;
  discount?: Maybe<Discount>;
};

export type OrderItem = {
  __typename?: "OrderItem";
  id?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

export type PaymentForm = {
  __typename?: "PaymentForm";
  nameOnCard: Scalars["String"];
  useAddressType: AddressType;
  address?: Maybe<Address>;
  acceptsMarketing: Scalars["Boolean"];
};

export type PaymentFormInput = {
  nameOnCard?: Maybe<Scalars["String"]>;
  address?: Maybe<Address>;
  useAddressType?: Maybe<Scalars["Boolean"]>;
};

export type Petal = {
  __typename?: "Petal";
  view: PetalView;
};

export type PetalView = {
  __typename?: "PetalView";
  isMenuOpen: Scalars["Boolean"];
  isAuthOpen: Scalars["Boolean"];
};

export type PetalViewInput = {
  isMenuOpen: Scalars["Boolean"];
};

export type Query = {
  __typename?: "Query";
  order?: Maybe<OrderDetails>;
  checkoutForm?: Maybe<CheckoutForm>;
  cart?: Maybe<Cart>;
  view?: Maybe<View>;
  petal: Petal;
};

export type View = {
  __typename?: "View";
  isCartOpen: Scalars["Boolean"];
  isUpdatingDiscountCode: Scalars["Boolean"];
};

export type ViewInput = {
  isCartOpen?: Maybe<Scalars["Boolean"]>;
  isUpdatingDiscountCode?: Maybe<Scalars["Boolean"]>;
};
