import * as React from 'react';
import styled, { css } from '@fe/styles';
import Spinkit from 'react-spinkit';
import { Portal as ReactPortal } from 'react-portal';

const Overlay = styled.div<{
  isShowing?: boolean;
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.color.overlay};
  z-index: ${(p) => p.theme.depth.front};
  opacity: 0;
  pointer-events: none;
  ${(p) => p.isShowing && css`
    opacity: 1;
    pointer-events: auto;
  `}
`;
const ContentContainer = styled.div`
  padding: 60px 40px;
  text-align: center;
`;
const LoadingMessage = styled.div < {
  color?: string,
  isShowing?: boolean,
} > `
  margin-top: 30px;
  transition: ${(p) => p.theme.transition.default};
  opacity: 0;
  color: ${(p) => p.color || `black`};
  ${(p) => p.isShowing && css`
    transition-delay: 1s;
    opacity: 1;
  `}
`;
const HideContent = styled.div<{
  isVisible: boolean,
}>`
  transition: ${(p) => p.theme.transition.default};
  ${(p) => p.isVisible ? css`
    opacity: 1;
  ` : css`
    opacity: 0;
    pointer-events: none;
  `}
`;
const SpinnerContainer = styled.div`
  min-height: 25px;
`;

export const Spinner: React.SFC<{
  className?: string,
  color?: string,
}> = ({ className, color, ...rest }) => (
  <SpinnerContainer className={className}>
    <Spinkit name='ball-pulse-sync' color={color || 'currentColor'} fadeIn={'none'} {...rest} />
  </SpinnerContainer>
);
export const Screen: React.SFC<{
  isLoading?: boolean,
  message?: string,
}> = ({ isLoading, message }) => (
  <ReactPortal>
    <Overlay isShowing={isLoading}>
      <ContentContainer>
        <Spinner color={`white`} />
        <LoadingMessage isShowing={isLoading} color={`white`} >
          {message}
        </LoadingMessage>
      </ContentContainer>
    </Overlay>
  </ReactPortal>
);
export const Contained: React.SFC<{
  alwaysRender?: boolean,
  children?: JSX.Element,
  isLoading?: boolean,
  message?: string,
}> = ({ isLoading, message, alwaysRender, children }) =>
  alwaysRender
    ? (
      <>
        {isLoading && (
          <ContentContainer>
            <Spinner />
            <LoadingMessage isShowing={isLoading}>
              {message}
            </LoadingMessage>
          </ContentContainer>
        )}
        <HideContent isVisible={!isLoading}>
          {children}
        </HideContent>
      </>
    ) : (
      <>
        {isLoading ? (
          <ContentContainer>
            <Spinner />
            <LoadingMessage isShowing={isLoading}>
              {message}
            </LoadingMessage>
          </ContentContainer>
        ) : (
          children
        )}
      </>
    );
