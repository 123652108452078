import * as React from 'react';

export default class Close extends React.Component {
  public render = () => {
    return (
    <svg {...this.props} viewBox='0 0 41 41' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Post-Checkout' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='icon/info' transform='translate(-3.164062, -3.359375)' fill='currentColor' fillRule='nonzero'>
                <path d='M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z M23.972,18.412 C22.74,18.412 21.76,17.432 21.76,16.2 C21.76,14.968 22.74,13.96 23.972,13.96 C25.204,13.96 26.212,14.968 26.212,16.2 C26.212,17.432 25.204,18.412 23.972,18.412 Z M21.928,32.468 L21.928,22.332 C21.928,22.024 22.18,21.8 22.46,21.8 L25.54,21.8 C25.82,21.8 26.072,22.024 26.072,22.332 L26.072,32.468 C26.072,32.748 25.82,33 25.54,33 L22.46,33 C22.18,33 21.928,32.748 21.928,32.468 Z' id='Combined-Shape'></path>
            </g>
        </g>
    </svg>
    );
  }
}
