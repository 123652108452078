import * as React from 'react';
import { connect } from 'formik';
import styled from '@fe/styles';

import InputWrapper from './inputWrapper';
import { InputContainer, InputTextCss } from './components';

const FakeInput = styled.div`
  ${InputTextCss}
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

@(connect)
export default class InputBlank<T> extends InputWrapper<T> {
  protected renderInput = () => {
    const {
      isValid,
      disabled,
      refLink,
      getErrorMessage,
      label,
      placeholder,
      iconEnd,
      onChange,
      value,
      // Take this off rest,
      ...rest
    } = this.props;

    return (
      <InputContainer isValid disabled={!!disabled}>
        <FakeInput
          innerRef={refLink}
          isValid
          isShowingLabel={!this.isEmpty}
          placeholder={placeholder === undefined ? label : placeholder}
          {...rest}
        >
          {value}
        </FakeInput>
      </InputContainer>
    );
  }
}
