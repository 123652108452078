import * as React from 'react';

export default class Tick extends React.Component {
  public render = () => {
    return (
      <svg {...this.props} viewBox='0 0 39 32' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Sign-Up' stroke='none' strokeWidth='1' fill='currentColor' fillRule='evenodd'>
          <g id='CheckOut/Desktop/2_AddressEntered' transform='translate(-83.000000, -614.000000)' fillRule='nonzero'>
            <g id='icon/tick' transform='translate(78.335938, 605.414062)'>
              <path d='M11.9815172,35.9815172 L5,29 L9,25 L15.9842576,31.9842576 L39.0000292,9 L43,13 L16,40 L11.9630091,36 L11.9815172,35.9815172 Z' id='Combined-Shape'></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
