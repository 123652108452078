import * as React from 'react';

export default class Password extends React.Component {
  public render = () => {
    return (
      <svg width='14px' height='18px' viewBox='0 0 29 36' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Sign-Up' stroke='none' strokeWidth='1' fill='currentColor' fillRule='evenodd'>
          <g id='CheckOut/Desktop/3_BillingAddress' transform='translate(-922.000000, -859.000000)' fillRule='nonzero'>
            <g id='icon/password' transform='translate(912.980469, 852.125000)'>
              <path d='M12,22 L12,19 C12,12.372583 17.372583,7 24,7 C30.627417,7 36,12.372583 36,19 L36,22 L38,22 L38,42 L10,42 L10,22 L12,22 Z M17,22 L31,22 L31,19 C31,15.1340068 27.8659932,12 24,12 C20.1340068,12 17,15.1340068 17,19 L17,22 Z M22.7872138,31.7447516 L21,38 L27,38 L25.2127862,31.7447516 C26.2654951,31.278921 27,30.2252369 27,29 C27,27.3431458 25.6568542,26 24,26 C22.3431458,26 21,27.3431458 21,29 C21,30.2252369 21.7345049,31.278921 22.7872138,31.7447516 Z' id='Combined-Shape'></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
