import * as React from 'react';

export default class Calendar extends React.Component {
  public render = () => {
    return (
        <svg {...this.props} viewBox='0 0 37 43' version='1.1' xmlns='http://www.w3.org/2000/svg'>
            <g id='Sign-Up' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='CheckOut/Desktop/2_AddressEntered' transform='translate(-99.000000, -379.000000)' fill='#000000' fillRule='nonzero'>
                    <g id='icon/calendar' transform='translate(93.070312, 377.992188)'>
                        <path d='M42,14 L42,44 L6,44 L6,14 L6,6 L11,6 L11,11 L16,11 L16,6 L32,6 L32,11 L37,11 L37,6 L42,6 L42,14 Z M12,2 L15,2 L15,10 L12,10 L12,2 Z M33,2 L36,2 L36,10 L33,10 L33,2 Z M8,17.0344828 L8,42.0344828 L40,42.0344828 L40,17.0344828 L8,17.0344828 Z M12,21 L15,21 L15,24 L12,24 L12,21 Z M12,28 L15,28 L15,31 L12,31 L12,28 Z M19,21 L22,21 L22,24 L19,24 L19,21 Z M19,28 L22,28 L22,31 L19,31 L19,28 Z M26,21 L29,21 L29,24 L26,24 L26,21 Z M26,28 L29,28 L29,31 L26,31 L26,28 Z M33,21 L36,21 L36,24 L33,24 L33,21 Z M33,28 L36,28 L36,31 L33,31 L33,28 Z M12,35 L15,35 L15,38 L12,38 L12,35 Z M19,35 L22,35 L22,38 L19,38 L19,35 Z M26,35 L29,35 L29,38 L26,38 L26,35 Z M33,35 L36,35 L36,38 L33,38 L33,35 Z' id='Combined-Shape'></path>
                    </g>
                </g>
            </g>
        </svg>
    );
  }
}
