import * as React from 'react';
import * as ReactAutosuggest from 'react-autosuggest';
import styled from '@fe/styles';
import InputWrapper from './inputWrapper';
import { InputContainerCss, InputTextCss, InputButton, IInputContainer } from './components';

import { connect, Field, FieldProps } from 'formik';
import * as gtm from '@fe/services/gtm';
import { string } from 'prop-types';

const Styling = styled.div<{
  isShowingLabel: boolean,
} & IInputContainer>`
  width: 100%;
  position: relative;

  .react-autosuggest__container {
    position: relative;
    ${InputContainerCss}
  }

  .react-autosuggest__input {
    ${InputTextCss}
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    width: calc(100% + 2px);
    position: relative;
    left: -1px;
    min-height: 60px;
    border: 1px solid ${(p) => p.theme.color.lightGrey};
    border-top: 0;
    background-color: #fff;
    font-size: 16px;
    z-index: ${(p) => p.theme.depth.foreground};
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    cursor: pointer;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestion {
    padding: 6px 10px;
    margin: 6px 0;
    color: ${(p) => p.theme.color.input.default};
    transition: ${(p) => p.theme.transition.default};
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${(p) => p.theme.color.input.optionHover};
    transition: ${(p) => p.theme.transition.quick};
  }
`;
const Suggestion = styled.div`
  cursor: pointer;
`;

type AutosuggestProps<T> = {
  allowCustomValue?: boolean,
  defaultErrorMessage?: string;
  getSuggestionLabel?: (suggestion: T) => string | JSX.Element,
  getSuggestionLabelText?: (suggestion: T) => string,
  getValueFromSelected?: (suggestion: T, value: string) => string,
  isManualTrigger?: boolean,
  manualTriggerText?: string,
  value: T;
  validate?: (value?: string) => string | undefined,
} & Partial<ReactAutosuggest.AutosuggestProps<T>>;

@(connect)
export default class AutosuggestInput<T> extends InputWrapper<
  AutosuggestProps<T>,
  {
    lastInputValue: string;
    selectedInputValue: string;
    selectedSuggestion: T | null,
    valueSelected: boolean;
  }> {
  public static defaultProps = {
    ...InputWrapper.defaultProps,
    highlightFirstSuggestion: true,
    defaultErrorMessage: `please enter a valid value`,
    getSuggestionValue: (suggestion) => suggestion.value,
    getSuggestionLabelText: (suggestion) => suggestion.label,
    getSuggestionLabel: (suggestion) => suggestion.label,
    allowCustomValue: false,
    getValueFromSelected: (suggestion) => suggestion,
    isManualTrigger: false,
    manualTriggerText: `search`,
    validate: () => undefined,
  };
  private inputRef: HTMLInputElement;

  constructor(props: any) {
    super(props);
    const value = props.formik.values[props.name] || '';
    this.state = {
      ...this.state,
      valueSelected: !!value,
      lastInputValue: value,
      selectedInputValue: value,
      selectedSuggestion: null,
    };
  }
  private onSuggestionSelected: ReactAutosuggest.OnSuggestionSelected<T> = (event, d) => {
    const value = this.props.getSuggestionValue!(d.suggestion);

    this.setState({
      valueSelected: true,
      selectedInputValue: value,
      selectedSuggestion: d.suggestion,
    });
    this.props.formik.setFieldValue(this.name, value);
    this.props.onSuggestionSelected!(event, d);
    gtm.sendSelect(`typeahead`, this.name, d.suggestion);
  }

  public onChange = (_event: React.FormEvent<any>, params: ReactAutosuggest.ChangeEvent): void => {
    if (params.method !== 'click') this.props.formik.setFieldValue(this.name, params.newValue);
  }

  public renderSuggestion = (suggestion: any): JSX.Element => {
    return (
      <Suggestion>
        {this.props.getSuggestionLabel!(suggestion)}
      </Suggestion>
    );
  }

  public renderInput = () => {
    const {
      placeholder,
      onSuggestionsFetchRequested,
      onSuggestionSelected,
      allowCustomValue,
      disabled,
      renderSuggestion,
      manualTriggerText,
      isManualTrigger,
      validate,

      ...rest
    } = this.props;

    return (
      <Styling
        isValid={this.isValid}
        isShowingLabel={!this.isEmpty}
        disabled={!!disabled}
        isFocused={this.isFocused}
        isDirty={this.isDirty}
      >
        <Field
          name={this.name}
          validate={validate}
          render={(props: FieldProps) => {
            return (
              <>
                <ReactAutosuggest
                  renderSuggestion={renderSuggestion || this.renderSuggestion}
                  renderInputComponent={({ ref, ...inputRest }) => {
                    return (
                      <input
                        {...inputRest}
                        name={this.name}
                        ref={(i) => {
                          ref(i);
                          this.inputRef = i!;
                        }}
                      />
                    );
                  }
                  }
                  inputProps={{
                    placeholder,
                    ...props.field,
                    value: props.field.value || '',
                    // value: props.field.value || '',
                    // onChange: this.onChange,
                  }}
                  onSuggestionSelected={this.onSuggestionSelected}
                  onSuggestionsFetchRequested={(request) => {
                    if (!isManualTrigger) onSuggestionsFetchRequested(request);
                  }}
                  {...rest}
                />
                {isManualTrigger && (
                  <InputButton
                    disabled={!this.value}
                    type={`button`}
                    onClick={() => {
                      onSuggestionsFetchRequested!({
                        value: this.value,
                        reason: `input-changed`,
                      });
                      if (this.inputRef) this.inputRef.focus();
                    }}
                  >
                    {manualTriggerText!}
                  </InputButton>
                )}
              </>
            );
          }}
        />
      </Styling>
    );
  }
}
