import styled from '@fe/styles';

import * as e from '@fe/components/Elements';

import CxLogo from '@fe/public/logos/CX-logo.svg';

export const Logo = styled(CxLogo)`
  width: 150px;
`;
export const Footer = styled.section`
  background: ${(p) => p.theme.color.blue};
  padding: 60px 40px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
`;
export const BottomRow = styled.div`
  ${(p) => p.theme.media.phablet`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  `}
`;
export const ColumnContainer = styled.div`
  display: grid;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  grid-template-columns: auto auto;
  grid-row-gap: ${(p) => p.theme.layout.spacingVertical};
  ${(p) => p.theme.media.tablet`
    grid-template-columns: auto auto auto auto;
  `}
  ${(p) => p.theme.media.desktop`
    width: auto;
  `}
`;
export const Column = styled.div`
  display: inline-block;
  min-width: 180px;
  width: 100%;
  ${(p) => p.theme.media.desktop`
    width: 180px;
  `}
`;
export const ColumnTitle = styled(e.Text.h4)`
  font-size: 20px;
  padding-left: 0;
  padding-right: 0;
`;
export const Link = styled(e.Button.Link)`
  display: block;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
`;

export const Copyright = styled.p`
  opacity: 0.3;
  margin-bottom: 10px;
`;
