import * as React from 'react';

import styled, { css } from '@fe/styles';
import * as e from '@fe/components/Elements';
import * as c from './components';
import { connect, Field } from 'formik';
import { InputType } from './types';

import * as gtm from '@fe/services/gtm';

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  font-family: ${(p) => p.theme.font.sans};
`;

const LabelContainer = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CheckmarkContainer = styled.span<{
  checked: boolean,
}>`
  display: inline-block;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.color.lightGrey};
  margin-right: 10px;
`;
const Checkmark = styled(e.Icon.Tick) <{
  checked: boolean;
}>`
  position: relative;
  right: 1px;
  padding: 2px;
  opacity: 0;
  transform: scale(0.8);
  transition: ${(p) => p.theme.transition.default};
  ${(p) => p.checked && css`
    opacity: 1;
    transform: scale(1);
  `}
`;

const LabelText = styled.div`
  display: inline-block;
  font-size: 18px;
  text-align: left;
`;
const InvisibleInput = styled(Field)<{
}>`
  position: absolute;
  pointer-events: none;
  opacity: 0;
`;

@(connect)
export default class InputComponent<P> extends React.Component<InputType<P>> {

  protected get name(): string {
    return this.props.name;
  }
  protected get value(): boolean {
    return this.props.formik.values[this.name];
  }
  protected get isEmpty(): boolean {
    return !this.value;
  }
  protected get isValid(): boolean {
    return !!this.errorMessage;
  }
  protected get isDirty(): boolean {
    return !this.props.formik.touched[this.name];
  }
  protected get isPristine(): boolean {
    return !this.isDirty;
  }
  protected get errorMessage(): string {
    return this.props.formik.errors[this.name];
  }

  public render = () => {
    const {
      label,
      name,
    } = this.props;
    // const checked = this.value;

    return (
      <ComponentContainer>
        <LabelContainer>
          <InvisibleInput
            type='checkbox'
            name={name}
            value={this.value}
            onChange={() => {
              this.props.formik.setFieldValue(name, !this.value);
              gtm.sendSelect(`checkbox`, name, {
                value: !this.value,
              });
            }}
          />
          <CheckmarkContainer checked={this.value}>
            <Checkmark checked={this.value} />
          </CheckmarkContainer>
          <LabelText>{label}</LabelText>
        </LabelContainer>
        <c.ErrorMessage name={name} />
      </ComponentContainer>
    );
  }
}
