import styled, { defaults } from '@fe/styles';
import withReactSizes from 'react-sizes';

export const NotMobile = styled.div`
  display: block;
  ${(p) => p.theme.media.phone`
    display: none;
  `}
`;
export const Mobile = styled.div`
  display: none;
  ${(p) => p.theme.media.phone`
    display: block;
  `}
`;
export const NotTablet = styled.div`
  display: block;
  ${(p) => p.theme.media.tablet`
    display: none;
  `}
`;
export const Tablet = styled.div`
  display: none;
  ${(p) => p.theme.media.tablet`
    display: block;
  `}
`;
export const NotDesktop = styled.div`
  display: block;
  ${(p) => p.theme.media.desktop`
    display: none;
  `}
`;
export const Desktop = styled.div`
  display: none;
  ${(p) => p.theme.media.desktop`
    display: block;
  `}
`;
export const NotCustom = styled.div<{
  width: string,
}>`
  display: block;
  @media (min-width: ${(p) => p.width}) {
    display: none;
  }
`;
export const Custom = styled.div<{
  width: string,
}>`
  display: none;
  @media (min-width: ${(p) => p.width}) {
    display: block;
  }
`;

interface IDevice {
  desktop: boolean;
  largeDesktop: boolean;
  mobile: boolean;
  phablet: boolean;
  tablet: boolean;
}
export interface ISizes {
  greaterThan: IDevice;
  height: number;
  is: IDevice;
  width: number;
}
export const getSizeInfo = ({ width, height }): ISizes => ({
  width,
  height,
  is: {
    mobile: width === defaults.media.sizes.phone,
    phablet: width === defaults.media.sizes.phablet,
    tablet: width === defaults.media.sizes.tablet,
    desktop: width === defaults.media.sizes.desktop,
    largeDesktop: width === defaults.media.sizes.largeDesktop,
  },
  greaterThan: {
    mobile: width > defaults.media.sizes.phone,
    phablet: width > defaults.media.sizes.phablet,
    tablet: width > defaults.media.sizes.tablet,
    desktop: width > defaults.media.sizes.desktop,
    largeDesktop: width > defaults.media.sizes.largeDesktop,
  },
});
export const withSizes = (propName = `sizes`) => withReactSizes((args) => ({
  [propName]: getSizeInfo(args),
}));

export default withSizes;
