import styled, { css } from '@fe/styles';

import FullLogo from '@fe/public/images/allplants/allplants-logo';
import FacebookLogo from '@fe/public/logos/social/Facebook';
import TwitterLogo from '@fe/public/logos/social/Twitter';
import InstagramLogo from '@fe/public/logos/social/Instagram';

const SocialIcon = css`
  height: 20px;
  width: 20px;
  margin: 0 10px;
`;
export const Facebook = styled(FacebookLogo)`${SocialIcon}`;
export const Twitter = styled(TwitterLogo)`${SocialIcon}`;
export const Instagram = styled(InstagramLogo)`${SocialIcon}`;

export {
  FullLogo,
};
