// Root entry point

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from 'react';
import Helmet from 'react-helmet';
import { hot } from 'react-hot-loader';
import config from '@fe/config';
import Routes from './website';

/* Local */

// Styles - import for side-effects
import '../styles';

import ScrollTop from '@fe/helpers/scrollTop';
import DevMessage from '@fe/helpers/devMessage';

const Root: React.SFC<{disableAnalytics: boolean}> = ({ disableAnalytics }) => (
  <div>
    <Helmet
      titleTemplate={config.seo.titleTemplate}
      defaultTitle={config.seo.title}
    >
      <title>{config.seo.title}</title>

      <meta charSet='utf-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta httpEquiv='Content-Language' content='en' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <link rel='apple-touch-icon-precomposed' sizes='57x57' href='/favicons/apple-touch-icon-57x57.png' />
      <link rel='apple-touch-icon-precomposed' sizes='114x114' href='/favicons/apple-touch-icon-114x114.png' />
      <link rel='apple-touch-icon-precomposed' sizes='72x72' href='/favicons/apple-touch-icon-72x72.png' />
      <link rel='apple-touch-icon-precomposed' sizes='144x144' href='/favicons/apple-touch-icon-144x144.png' />
      <link rel='apple-touch-icon-precomposed' sizes='60x60' href='/favicons/apple-touch-icon-60x60.png' />
      <link rel='apple-touch-icon-precomposed' sizes='120x120' href='/favicons/apple-touch-icon-120x120.png' />
      <link rel='apple-touch-icon-precomposed' sizes='76x76' href='/favicons/apple-touch-icon-76x76.png' />
      <link rel='apple-touch-icon-precomposed' sizes='152x152' href='/favicons/apple-touch-icon-152x152.png' />
      <link rel='icon' type='image/png' href='/favicons/favicon-196x196.png' sizes='196x196' />
      <link rel='icon' type='image/png' href='/favicons/favicon-96x96.png' sizes='96x96' />
      <link rel='icon' type='image/png' href='/favicons/favicon-32x32.png' sizes='32x32' />
      <link rel='icon' type='image/png' href='/favicons/favicon-16x16.png' sizes='16x16' />
      <link rel='icon' type='image/png' href='/favicons/favicon-128.png' sizes='128x128' />
      <meta name='application-name' content={config.seo.companyName} />
      <meta name='msapplication-TileColor' content='#FFFFFF' />
      <meta name='msapplication-TileImage' content='mstile-144x144.png' />
      <meta name='msapplication-square70x70logo' content='mstile-70x70.png' />
      <meta name='msapplication-square150x150logo' content='mstile-150x150.png' />
      <meta name='msapplication-wide310x150logo' content='mstile-310x150.png' />
      <meta name='msapplication-square310x310logo' content='mstile-310x310.png' />

      {/* <!-- Social --> */}
      <meta name='description' content={config.seo.description} />
      <meta name='keywords' content={config.seo.keywords} />
      {/* <!-- Schema.org markup for Google+ --> */}
      <meta itemprop='name' content={config.seo.title} />
      <meta itemprop='description' content={config.seo.description} />
      <meta itemprop='image' content={`${config.seo.baseUrl}/static/images/seo/logo-sq.jpg`} />
      {/* <!-- Twitter Card data --> */}
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content={config.seo.twitterUser} />
      <meta name='twitter:title' content={config.seo.title} />
      <meta name='twitter:description' content={config.seo.description} />
      <meta name='twitter:image' content={`${config.seo.baseUrl }/static/images/seo/logo-sq.jpg`} />
      <meta name='twitter:image:alt' content={config.seo.companyName} />
      {/* <!-- Open Graph data --> */}
      <meta property='og:title' content={config.seo.title} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={config.seo.baseUrl} />
      <meta property='og:image' content={`${config.seo.baseUrl}/static/images/seo/logo-sq.jpg`} />
      <meta property='og:description' content={config.seo.description} />

      {/* <!-- Styles --> */}
      <link rel='preload' as='style' href='/fonts/CeraGR/stylesheet.css' />
      <link rel='stylesheet' href='/fonts/CeraGR/stylesheet.css' />
      {/* <!-- End Styles --> */}

      {/* <!-- Third Party --> */}
      {/* <!-- Analytics --> */}
      {/* <!-- Google Tag Manager Data Layer --> */}
      <script>{`
        window.dataLayer=window.dataLayer||[];
      `}</script>
      {/* <!-- End Google Tag Manager Data Layer --> */}
      {disableAnalytics && (
        <script>{`
          console.warn('ANALYTICS DISABLED');
        `}</script>
      )}
      {/* <!-- Google Tag Manager --> */}
      {!disableAnalytics && (
        <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${config.analytics.gtm.params}';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${config.analytics.gtm.id}');
        `}</script>
      )}
      {/* <!-- End Google Tag Manager --> */}
      {/* <!-- Google Tag Manager (noscript) --> */}
      {!disableAnalytics && (
        <noscript>{`
          <iframe src='https://www.googletagmanager.com/ns.html?id=${config.analytics.gtm.id}${config.analytics.gtm.params}'
          height='0' width='0' style='display:none;visibility:hidden'></iframe>
        `}</noscript>
      )}
      {/* <!-- End Google Tag Manager (noscript) --> */}
      
      {/* <!-- End Analytics --> */}

      <script>{`
        window.prismic = {
          endpoint: 'https://allplants-cms.cdn.prismic.io/api/v2'
        };
      `}</script>
      <script async type='text/javascript' src='https://static.cdn.prismic.io/prismic.min.js'></script>

      {/* <!-- Third Party End --> */}

    </Helmet>
    <ScrollTop>
      {disableAnalytics && (<DevMessage>analytics disabled</DevMessage>)}
      <Routes />
    </ScrollTop>
  </div>
);

export default hot(module)(Root);
