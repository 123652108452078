import 'airbnb-js-shims';
import 'react-dates/initialize';
import * as React from 'react';
import { SingleDatePicker } from 'react-dates';

import styled, { css } from '@fe/styles';
import InputWrapper from './inputWrapper';
import * as moment from 'moment';
import * as e from '@fe/components/Elements';
import * as c from './date.components';
import { withSizes, ISizes } from '@fe/components/Size';
import { connect, Field, FieldProps } from 'formik';
import * as gtm from '@fe/services/gtm';

const CalendarIcon = styled(e.Icon.Calendar)`
  position: relative;
  z-index: ${(p) => p.theme.depth.foreground};
`;
interface IDatePicker {
  isDayBlocked: (date: moment.Moment) => boolean;
  isDayHighlighted: (date: moment.Moment) => boolean;
  onDateChange?: (date: moment.Moment) => void;
}

@(connect)
@(withSizes())
export default class DatePicker extends InputWrapper<{
  sizes: ISizes,
  dateFormat?: string,
  invalidDateMessage?: string,
} & IDatePicker, {
  isFocused: boolean,
}> {

  public static defaultProps = {
    ...InputWrapper.defaultProps,
    onDateChange: () => null,
    invalidDateMessage: `please select a valid date`,
  };
  private inputRef = React.createRef<HTMLElement>();

  protected manuallySetFocusedAndDirty = true;

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isFocused: false,
    };
  }

  protected validate = (value): string | undefined => {
    const {
      isDayBlocked,
      invalidDateMessage,
    } = this.props;
    if (value) {
      const date = moment(value);
      if (date.isValid && isDayBlocked(date)) {
        return invalidDateMessage;
      }
    }
  }

  protected renderInput = () => {
    const {
      isFocused,
  } = this.state;
    const {
      label,
      iconEnd,
      onDateChange,
      isDayBlocked,
      isDayHighlighted,
      sizes,
      formik,
      dateFormat,

      // Remove from rest
      invalidDateMessage,
      name,
      value,
      onChange,
      defaultErrorMessage,
      ...rest
    } = this.props;

    const isTabletOrSmaller = !sizes.greaterThan.tablet;

    return (
      <c.AllplantsStyling
        isValid={this.isValid}
        isFocused={this.isFocused}
        isDirty={this.isDirty}
        isShowingLabel={!this.isEmpty}
        innerRef={this.inputRef}
      >
        <Field
          name={this.name}
          validate={this.validate}
          render={(formikProps: FieldProps) => {
            return (
              <SingleDatePicker
                id={this.name}
                displayFormat={dateFormat ? dateFormat : `dddd Do MMMM YYYY`}
                focused={isFocused}
                onFocusChange={({ focused }) => {
                  if (focused !== isFocused) this.setState({ isFocused: !!focused });
                  if (!focused) formik.setFieldTouched(this.name, true);
                }}
                onClose={() => {
                  if (isFocused) this.setState({ isFocused: false });
                  formik.setFieldTouched(this.name, true);
                  if (isTabletOrSmaller && this.inputRef.current) {
                    this.inputRef.current!.scrollIntoView();
                  }
                }}
                inputIconPosition='after'
                customInputIcon={<CalendarIcon />}
                readOnly
                isDayBlocked={isDayBlocked}
                isDayHighlighted={isDayHighlighted}
                firstDayOfWeek={1}
                hideKeyboardShortcutsPanel
                // Switch on tablet
                navPrev={isTabletOrSmaller ? undefined : <c.Arrow direction={`left`} />}
                navNext={isTabletOrSmaller ? undefined : <c.Arrow direction={`right`} />}
                orientation={isTabletOrSmaller ? 'vertical' : 'horizontal'}
                withFullScreenPortal={isTabletOrSmaller}
                calendarInfoPosition={isTabletOrSmaller ? 'top' : 'bottom'}
                disableScroll={isTabletOrSmaller ? isFocused : undefined}
                // End switch
                renderCalendarInfo={() => {
                  return (
                    <c.Legends isTop={isTabletOrSmaller}>
                      <c.LegendContainer>
                        <c.LegendColor background={css`${(p) => p.theme.color.primary}`} />
                        <c.LegendText>free</c.LegendText>
                      </c.LegendContainer>
                      <c.LegendContainer>
                        <c.LegendColor background={css`url(${c.Hatching})`} />
                        <c.LegendText>£2.50</c.LegendText>
                      </c.LegendContainer>
                    </c.Legends>
                  );
                }}
                {...rest}
                date={formikProps.field.value ? moment(formikProps.field.value) : null}
                onDateChange={(date) => {
                  onDateChange!(date);
                  formik.setFieldValue(this.name, date ? date.toISOString() : '');
                  gtm.sendSelect(`date`, this.name, { date });
                }}
              />
            );
          }}
        />
      </c.AllplantsStyling>
    );
  }
}
