// Default theme

// ----------------------------------------------------------------------------
// IMPORTS

/* Local */
import { css } from 'styled-components';
import media from './media';
// ----------------------------------------------------------------------------

const font = {
  header: `'Panton', sans-serif`,
  sans: `'Cera GR', sans-serif`,
  serif: `'Tiempos Headline', serif`,
};
const depth = {
  back: -9999,
  background: -99,
  behind: -9,
  neutral: 0,
  infront: 9,
  foreground: 99,
  front: 9999,
};
const color = {
  page: '#F8F8FA;',
  overlay: 'rgba(0,0,0,0.6)',
  background: '#f7f7f7',
  backgroundGrey: '#F3F3F2',
  backgroundLightGrey: '#F3F3F2',
  backgroundActive: '#e9e9e9',
  lightText: '#747678',
  text: '#50525D',
  accent: '#cbcccd',
  accentComplementary: '#26292c',
  blue: `#F8F8FA`,
  green: `#20C79D`,
  darkGreen: `#009D99`,
  border: '#cbcccd',
  borderLight: '#e5e5e5',
  primary: '#20C79D',
  primaryComplementary: 'white',
  orange: '#FFA500',
  yellow: '#FADB7E',
  lightYellow: '#FCEFCD',
  disabled: '#898B8C',
  grey: '#aeafb0',
  dark: '#202225',
  darkComplementary: '#404346',
  faint: '#b9babb',
  red: '#f94839',
  redComplementary: '#fee9e7',
  lightGrey: '#CFD0CB',
  lighterGrey: '#E7E7E5',
  lightGreen: '#97cd97',
  darkGrey: '#666666',
  lighterGreen: '#99cc99',
  paleYellow: '#fef8e5',
  button: {
    default: '#20C79D',
    active: '#20C79D',
    disabled: '#20C79D',
    hover: '#20C79D',
  },
  link: {
    default: `#005957`,
  },
  input: {
    default: '#111111',
    active: '#0D67DE',
    hover: '#0D67DE',
    focused: '#0D67DE',
    complete: '#33557E',
    disabled: '#898B8C',
    optionHover: '#007eff38',
    text: '#747678',
    activeText: '#26292c',
    placeholder: '#A0A098',
  },
};
const easing = {
  easeInCubic: `cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  easeOutCubic: `cubic-bezier(0.215, 0.61, 0.355, 1)`,
  easeInOutCubic: `cubic-bezier(0.645, 0.045, 0.355, 1)`,
  easeInQuart: `cubic-bezier(0.895, 0.03, 0.685, 0.22)`,
  easeOutQuart: `cubic-bezier(0.165, 0.84, 0.44, 1)`,
  easeInOutQuart: `cubic-bezier(0.77, 0, 0.175, 1)`,
  easeOutQuint: `cubic-bezier(0.23, 1, 0.32, 1)`,
  easeInQuint: `cubic-bezier(0.755, 0.05, 0.855, 0.06)`,
  easeInOutQuint: `cubic-bezier(0.86, 0, 0.07, 1)`,
};
const transition = {
  defaultTime: '0.4s',
  default: `all 0.4s ${easing.easeOutCubic}`,
  slowTime: '0.8s',
  slow: `all 0.8s ${easing.easeOutCubic}`,
  quickTime: '0.2s',
  quick: `all 0.2s ${easing.easeOutCubic}`,
};
const layout = {
  width: '800px',
  maxWidth: '1024px',
  centerBlockMaxWidth: '640px',
  padding: '16px 24px',
  spacingHorizontal: `24px`,
  spacingVertical: `16px`,
  spacingHorizontalVal: 20,
  spacingVerticalVal: 30,
  paddingResizeCss: css`
    padding: 8px 16px;
    ${media.phablet`
      padding: 12px 20px;
    `}
    ${media.desktop`
      padding: 16px 24px;
    `}
  `,
  largePaddingResizeCss: css`
    padding: 20px 16px;
    ${media.phablet`
      padding: 30px 20px;
    `}
    ${media.desktop`
      padding: 40px 24px;
    `}
  `,
  margin: '16px',
  marginResizeCss: css`
    margin: 8px 16px;
    ${media.phablet`
      margin: 12px 20px;
    `}
    ${media.desktop`
      margin: 16px 24px;
    `}
  `,
  largeMarginResizeCss: css`
    margin: 20px 16px;
    ${media.phablet`
      margin: 30px 20px;
    `}
    ${media.desktop`
      margin: 40px 24px;
    `}
  `,
  letterSpacingSmall: '0.5px',
  letterSpacingMed: '0.75px',
  letterSpacingLarge: '1.4px',
};
const other = {
  border: `1px solid ${color.border}`,
  borderRadius: `6px`,
  borderLight: `1px solid ${color.borderLight}`,
  boxInvisible: '0 0 0 0 rgba(0,0,0,0)',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  boxShadowStrong: '0 2px 4px 0 rgba(0,0,0,0.2)',
};
const theme = {
  media,
  font,
  depth,
  color,
  easing,
  transition,
  layout,
  other,
  name: `DEFAULT`,
};

export default theme;
