
export const setParam = (name: string, value?: string) => {
  const history = require(`./history`);
  const newUrl = new URL(window.location.href);

  if (value) {
    newUrl.searchParams.set(name, value);
  } else {
    newUrl.searchParams.delete(name);
  }
  const relativePath = newUrl.href.replace(newUrl.origin, ``);
  history.default.push(relativePath);
};
