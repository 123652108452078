import { IServerState } from '$/serverState';

let state: IServerState | null;
if (typeof SERVER !== 'undefined' && SERVER) {
  state = null;
} else {
  state = (window as any).__STATE__;
}

export default state;
