import { combineReducers } from 'redux-immutable';
import { apolloReducer } from 'apollo-cache-redux';

import example from './example';

const rootReducer = combineReducers({
  example,
  apollo: apolloReducer,
});

export default rootReducer;
