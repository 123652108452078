import gql from 'graphql-tag';
import * as fragment from '../../../fragments';

import { Apollo } from '$/apollo';
import { PetalView } from '@graphql/types/client';

import updateCache from '@fe/graphql/services/updateCache';

export const updatePetalView: Apollo.MutationResolver<{ view: PetalView }>
 = (_, { view }, { cache }) => {
   const newData = updateCache<{
     petal: {
       view: Partial<PetalView>,
     },
   }>(cache)(gql`
    query UpdatePetalView {
      petal @client {
        view {
          isMenuOpen
        }
      }
    }
    ${fragment.paymentForm}
  `, {
    petal: { view },
  });
   return newData;
 };
