import * as React from 'react';

import * as e from '@fe/components/Elements';
import * as c from './components';

import * as Size from '@fe/components/Size';

import ExecSummary from '@fe/public/documents/Carbonex - Exec Summary.pdf';

export default class Footer extends React.Component {

  public render = () => {
    return (
      <c.Footer>
        <c.Row>
          <Size.Desktop>
            <c.Logo />
          </Size.Desktop>
          <c.ColumnContainer>
            <c.Column>
              <c.ColumnTitle>Support</c.ColumnTitle>
              <c.Link href={`mailto:support@carbonex.co`}>Account support</c.Link>
            </c.Column>
            <c.Column>
              <c.ColumnTitle>Connect</c.ColumnTitle>
              <c.Link href={`https://www.linkedin.com/company/carbonexplatform/`} target={`_blank`}>LinkedIn</c.Link>
              <c.Link href={`https://en-gb.facebook.com/CarbonexPlatform/`} target={`_blank`}>Facebook</c.Link>
              <c.Link href={`https://twitter.com/Carbonex`} target={`_blank`}>Twitter</c.Link>
            </c.Column>
            <c.Column>
              <c.ColumnTitle>Exchange</c.ColumnTitle>
              <c.Link href={`https://ico.carbonex.co`} target={`_blank`}>How it works?</c.Link>
            </c.Column>
            <c.Column>
              <c.ColumnTitle>Company</c.ColumnTitle>
              <c.Link href={`mailto:help@carbonex.co`} target={`_blank`}>Contact</c.Link>
              <c.Link href={ExecSummary} target={`_blank`}>About</c.Link>
              {/* <c.Link>Press</c.Link>
              <c.Link>Legal</c.Link> */}
            </c.Column>
          </c.ColumnContainer>
        </c.Row>
        <c.BottomRow>
          <e.Text.h4>
            <Size.NotDesktop>
              <c.Logo />
            </Size.NotDesktop>
            Let's change the future
          </e.Text.h4>
          <c.Copyright>
            © Carbonex Ltd 2019
          </c.Copyright>
        </c.BottomRow>
      </c.Footer>
    );
  }
}
