import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import ErrorBoundary from '@fe/components/ErrorBoundary';
import AppContainer from '@fe/views/common/AppContainer';
import LoginModal from '@fe/components/Modal/login';
import RegisterModal from '@fe/components/Modal/register';
import loadable from '@loadable/component';
import { parse } from 'query-string';
import { setParam } from '@fe/views/common/routeHandler';

const Home = loadable(() => import('./Homepage'));

export class Website extends React.Component {

  private get params() {
    return parse(this.props.location.search);
  }

  public render = () => {
    return (
      <ErrorBoundary>
        <AppContainer>
          <Switch>
            <Route exact path='/' component={Home} />
            <Redirect to={'/'} />
          </Switch>
          <LoginModal
            isOpen={this.params.auth === `login`}
            onClose={() => setParam(`auth`)}
          />
          <RegisterModal
            isOpen={this.params.auth === `register`}
            onClose={() => setParam(`auth`)}
          />
        </AppContainer>
      </ErrorBoundary>
    );
  }
}
export default withRouter(Website);

