import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import Header from '@fe/views/common/Header';
import Footer from '@fe/views/common/Footer';
import * as c from './components';

interface IProps extends RouteComponentProps {
  children: any;
}

export class AppContainer extends React.Component<IProps> {
  public render = () => {
    const {
      children,
    } = this.props;

    return (
      <c.AppContainer>
        <Header />
        {children}
        <Footer />
      </c.AppContainer>
    );
  }
}

export default withRouter(AppContainer);
