import * as React from 'react';

import * as c from './components';

export interface IModalProps {
  disableClose?: boolean;
  hideBehind: boolean;
  isOpen: boolean;
  ModalContent?: typeof c.ModalContent;
  ModalContentContainer?: typeof c.ModalContentContainer;
  ModalOverlay?: typeof c.ModalOverlay;
  onClose: () => void;
  unmountOnClose: boolean;
}
export default abstract class Modal<T = {}, S = {}> extends React.Component<IModalProps & T, S> {
  public static defaultProps = {
    ModalOverlay: c.ModalOverlay,
    ModalContentContainer: c.ModalContentContainer,
    ModalContent: c.ModalContent,
    unmountOnClose: false,
    hideBehind: false,
  };

  protected abstract renderContent: () => JSX.Element;

  protected tryClose = () => {
    const {
      onClose,
      disableClose,
    } = this.props;
    if (!disableClose) {
      onClose();
    }
  }

  public render = () => {
    const {
      isOpen,
      disableClose,
      ModalOverlay,
      ModalContentContainer,
      ModalContent,
      unmountOnClose,
      hideBehind,
    } = this.props;
    if (unmountOnClose && !isOpen) return null;

    return (
      <c.Portal>
        <ModalOverlay isOpen={isOpen} onClick={this.tryClose} hideBehind={hideBehind}>
          <ModalContentContainer onClick={(e) => e.stopPropagation()}>
            {!disableClose && (
              <c.CloseButtonContainer onClick={this.tryClose}>
                <c.Cross />
                close
              </c.CloseButtonContainer>
            )}
            <ModalContent>
              {this.renderContent()}
            </ModalContent>
          </ModalContentContainer>
        </ModalOverlay>
      </c.Portal>
    );
  }
}
